import React from 'react';
import {connect} from 'react-redux';
import ProductPage from "./ProductPage";
import {
	clear,
	deleteCategory,
	deleteGenericProduct,
	saveCategory,
	saveGenericProduct
} from "../../redux/products/productsActions";

function mapStateToProps({products}) {
	return {
		categories: products.categories,
		generics: products.generics,
		loading: products.loading,
		error: products.error,
		success: products.success
	};
}

const mapDispatchToProps = {
	saveCategory,
	deleteCategory,
	saveGenericProduct,
	deleteGenericProduct,
	clear
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductPage);

