import React from "react";
import ProfilePage from "./ProfilePage";
import {connect} from "react-redux";
import {updateDioceses, updateUser} from '../../redux/users/userActions';
import {getDioceseClosedInvoices, getDioceseOpenInvoices, closeInvoice, clear} from "../../redux/cards/cardsActions";
import NTMButton from "../../components/Buttons";
import moment from "moment";
import NTMLoadingModal from "../../components/Modal/NTMLoadingModal";
import {ErrorDialog, SuccessDialog, ConfirmDialog} from "@nextome/messages";
import SweetAlert from "react-bootstrap-sweetalert";

/*Function that associates the Profile status saved in the store, with the profile variable passed as props to profile page*/
function mapStateToProps({cards, users}) {
	return {
		dioceses: cards.dioceses,
		users: users.list,
		openinvoices: cards.openinvoices,
		closedinvoices: cards.closedinvoices,
		loading: cards.loading,
		success: cards.success,
		error: cards.error,
	};
}

const mapDispatchToProps = {
	updateDioceses,
	updateUser,
	getDioceseClosedInvoices,
	getDioceseOpenInvoices,
	closeInvoice,
	clear
};

class DioceseProfileContainer extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			diocese: this.props.match.params.dioceseid || this.props.location.pathname.split["/"].pop(),
			invoiceNumber: 1
		};
	}

	componentWillReceiveProps(props) {
		if (props.loading) {
			this.openModal(<NTMLoadingModal/>);
		} else if (props.error) {
			this.openModal(<ErrorDialog
				message={props.error}
				onClose={() => {
					this.props.clear();
					this.closeModal();
				}}
			/>);
		} else if (props.success) {
			this.openModal(
				<SuccessDialog
					title={"Operazione eseguita"}
					onClose={() => {
						this.props.clear();
						this.closeModal();
					}}
				/>
			);
		}
	}

	closeModal = () => {
		this.setState({
			modal: null
		});
	};

	openModal = (modal) => {
		this.setState({
			modal
		});
	};

	componentDidMount() {
		this.props.getDioceseOpenInvoices(this.state.diocese);
		this.props.getDioceseClosedInvoices(this.state.diocese);
	}

	changeDioceseAdmin = (diocese, stdSuperv, oldAdmin, newAdmin) => {

		let supervisors = stdSuperv.map(s => s.uid);

		if (oldAdmin.uid !== -1) {
			oldAdmin.type = 3;
			this.props.updateUser(oldAdmin);
			supervisors.push(oldAdmin.uid);
		}
		newAdmin.type = 5;
		this.props.updateUser(newAdmin);
		supervisors = supervisors.filter(s => s !== newAdmin.uid);

		let index = this.props.dioceses.findIndex(d => d.id.toString() === diocese);
		if (index > -1) {
			let d = this.props.dioceses.slice();
			d[index].supervisors = supervisors;
			this.props.updateDioceses(d);
		}

	};

	changeInvoiceNumber = (e) => this.setState({invoiceNumber: e.target.value});

	render() {

		let dioceseid = this.state.diocese;
		let diocese = this.props.dioceses.find(s => s.id.toString() === this.state.diocese);

		if (!diocese) return <div/>;

		let sections = [];
		let tables = [];

		sections.push({
			title: diocese.fiscalName,
			data: [
				{
					label: "Indirizzo",
					value: diocese.address
				},
				{
					label: "CAP",
					value: diocese.cap
				},
				{
					label: "Città",
					value: diocese.city
				},
				{
					label: "Email contatto",
					value: diocese.email
				},
				{
					label: "Telefono",
					value: diocese.tel
				},
				{
					label: "Indirizzo PEC",
					value: diocese.pec
				},
				{
					label: "Cod. Fiscale",
					value: diocese.fiscalCode
				},
				{
					label: "Partita IVA",
					value: diocese.piva
				},
				{
					label: "Cod. Fatturaz. Elettr.",
					value: diocese.recipientCode
				}
			]
		});

		let supervisors = this.props.users.filter(u => (u.type === 3 || u.type === 5) && u.dioceseId.toString() === dioceseid);

		if (supervisors && supervisors.length > 0) {
			let admin = supervisors.find(s => s.type === 5) || {uid: -1};
			let standard = supervisors.filter(s => s.uid !== admin.uid);

			sections.push({
				title: "Supervisore Amministratore",
				data: [
					{
						label: "Nome",
						value: admin.firstName
					}, {
						label: "Cognome",
						value: admin.lastName
					}, {
						label: "Città",
						value: admin.city
					}, {
						label: "Email",
						value: admin.email
					}, {
						label: "Data di nascita",
						value: admin.birthday
					}]
			});

			tables.push({
				title: "Elenco Supervisori",
				header:
					[
						{
							Header: 'Nome',
							accessor: 'firstName' // String-based value accessors!
						},
						{
							Header: 'Cognome',
							accessor: 'lastName',
						},
						{
							Header: 'Email',
							accessor: 'email'
						},
						{
							Header: 'Città',
							accessor: 'city'
						},
						{
							Header: 'Data di nascita',
							accessor: 'birthday'
						},
						{
							Cell: props => <NTMButton
								primary
								onClick={() => this.changeDioceseAdmin(dioceseid, standard, admin, props.original)}
							>
								Rendi amministratore
							</NTMButton>
						}
					],
				body: standard
			});
		}

		let openinvoices = this.props.openinvoices[dioceseid];
		let closedinvoices = this.props.closedinvoices[dioceseid];
		let lastInvoiceNumber = 0;
		if (closedinvoices && closedinvoices.length > 0) {
			let year = closedinvoices[0].invoiceNumber.split("/")[1];
			if (parseInt(year) === new Date().getFullYear())
				lastInvoiceNumber = parseInt(closedinvoices[0].invoiceNumber.split("/")[0]);
		}

		let openInvoiceTable = {
			title: "Fatture aperte",
			header: [
				{
					Header: "Data apertura fattura",
					Cell: props => <div>{moment(props.original.startDate).format("DD/MM/YYYY")}</div>
				},
				{
					Header: "Descrizione",
					Cell: props => <div>{
						props.original.invoiceBody.map((i, k) => <div
							key={k}>{i.description.replace("nel", "per il")}</div>)
					}</div>
				},
				{
					Header: "Totale",
					Cell: props => <div>{`${props.original.totalInvoice} €`}</div>
				},
				{
					Header: 'Chiudi Fattura',
					Cell: props => <NTMButton
						primary
						onClick={() => this.openModal(
							<SweetAlert
								showCancel
								confirmBtnText={"Conferma"}
								confirmBtnBsStyle={"danger"}
								cancelBtnText={"Annulla"}
								cancelBtnBsStyle={"default"}
								title={"Confermi?"}
								onConfirm={() => {
									this.props.closeInvoice(dioceseid, props.original.invoiceId, lastInvoiceNumber + 1);
								}}
								onCancel={this.closeModal}
							>
								{`Il numero d'ordine sarà ${lastInvoiceNumber + 1}/${new Date().getFullYear()}`}
							</SweetAlert>
						)}
					>{"Chiudi"}</NTMButton>
				}
			],
			body: openinvoices
		};
		tables.push(openInvoiceTable);


		let closedInvoiceTable = {
			title: "Fatture chiuse",
			header: [
				{
					Header: 'Numero fattura',
					accessor: 'invoiceNumber',
					sortMethod: (a, b) => {
						let splitteda = a.split("/");
						let splittedb = b.split("/");
						if (splitteda[1] === splittedb[1]) {
							return splittedb[0] - splitteda[0];
						} else return splittedb[1] - splitteda[1];
					}
				},
				{
					Header: "Data fattura",
					accessor: 'invoiceDate',
				},
				{
					Header: "Totale",
					accessor: 'value'
				},
				{
					Header: 'Scarica',
					Cell: props => <div>
						<a className={"btn"} href={props.original.link}><i className="fa fa-file-pdf-o"
						                                                   aria-hidden="true"></i></a>
						<a className={"btn"} href={props.original.eInvoiceLink}><i className="fa fa-file-excel-o"
						                                                           aria-hidden="true"></i></a>
					</div>
				}
			],
			body: closedinvoices
		};
		tables.push(closedInvoiceTable);


		return (
			<React.Fragment>
				{this.state.modal}
				<ProfilePage
					title={diocese.name}
					sections={sections}
					tables={tables}
				/>
			</React.Fragment>);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DioceseProfileContainer);
