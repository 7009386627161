import {firebaseAuth, firebaseDatabase, firebaseStorage} from '../../firebase';
import moment from "moment/moment";
import {APPACUTIS_INVOICES, APPACUTIS_OPEN_INVOICE, APPACUTIS_TRANSACTIONS, Client, USERS} from "../utils/Client";
import {
	getOpenInvoiceCreator,
	loading,
	saveInvoices,
	updateStorageLink,
	error,
	success,
	removeInvoice,
	CLEAR, LOADING, getTransactionsCreator
} from "./usersActionsCreator";
import {list as getProducts} from "../../redux/products/productsActions";
import {list as getUsers} from "../../redux/users/userActions";
import {list as getCards, listDioceses} from "../../redux/cards/cardsActions";
import {list as getShops, getShopCategories} from "../../redux/shops/shopsActions";

export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const GET_USERS = "GET_USERS";
export const SAVE = "SAVE";
export const UPDATE_PROFILE_DATA = "UPDATE PROFILE DATA";

let client = new Client();

export function login() {
	return dispatch => {
		firebaseAuth.onAuthStateChanged(user => {
			console.log("onAuthStateChanged:", user);
			if (user) {
				firebaseDatabase.ref("users/" + user.uid).once("value", (snapshot) => {
					dispatch({
						type: LOGIN,
						payload: snapshot.val()
					});
					dispatch(listDioceses());
					dispatch(getProducts());
					dispatch(getUsers());
					dispatch(getCards());
					dispatch(getShops());
					dispatch(getShopCategories());
				});
			} else {
				dispatch(logout());
			}
		});
	};
}

export function logout() {
	firebaseDatabase.ref("users").off();
	firebaseDatabase.ref("cards").off();
	firebaseDatabase.ref("diocese").off();
	firebaseDatabase.ref("generic_product").off();
	firebaseDatabase.ref("product_category").off();
	firebaseDatabase.ref("shops").off();
	firebaseDatabase.ref("shops_category").off();
	firebaseAuth.signOut();
	return {
		type: LOGOUT
	};
}

export function list() {
	return dispatch => {
		dispatch({
			type: LOADING,
			data: true
		});
		let userRef = firebaseDatabase.ref("users");
		userRef.on("value", data => {
			let userArray = [];
			data.forEach((childSnapshot) => {
				let item = childSnapshot.val();
				userArray.push(item);
			});
			dispatch({
				type: GET_USERS,
				data: userArray
			});
		}, err => {
			console.log(err);
		});
	};
}

export function setUserEnabled(uid, enabled) {
	return () => client.update({enabled}, `${USERS}/${uid}`);
}

export function updateUser(user) {
	return () => client.update(user, `${USERS}/${user.uid}`);
}

export function getInvoices() {
	return dispatch => {
		client.get(APPACUTIS_INVOICES)
			.then(response => {
				response.data.forEach(i => {
					i.invoiceDate = i.invoiceDate && moment(i.invoiceDate).format("DD/MM/YYYY");
					if (i.link) firebaseStorage.refFromURL(i.link).getDownloadURL()
						.then(url => dispatch(updateStorageLink(i.invoiceNumber, "link", url)))
						.catch(e => dispatch(removeInvoice(i.invoiceNumber)));
					if (i.eInvoiceLink) firebaseStorage.refFromURL(i.eInvoiceLink).getDownloadURL()
						.then(url => dispatch(updateStorageLink(i.invoiceNumber, "eInvoiceLink", url)))
						.catch(e => dispatch(removeInvoice(i.invoiceNumber)));
				});
				dispatch(saveInvoices(response.data));
			})
			.catch(e => manageError(e, dispatch));
	};
}

export function getOpenInvoice() {
	return dispatch => {
		client.get(APPACUTIS_OPEN_INVOICE)
			.then(response => {
				let i = response.data;
				i.startDate = i.startDate && moment(i.startDate).format("DD/MM/YYYY");
				dispatch(getOpenInvoiceCreator(i));
			})
			.catch(e => manageError(e, dispatch));
	};
}

export function closeInvoice(invoiceId, invoiceNumber) {
	return dispatch => {
		dispatch(loading());
		client.save(null, `${APPACUTIS_OPEN_INVOICE}/${invoiceId}/${invoiceNumber}`)
			.then(() => {
				dispatch(getInvoices());
				dispatch(getOpenInvoice());
				dispatch(success());
			})
			.catch(e => manageError(e, dispatch));
	};
}

export function updateProfile(profile = {}) {
	const UID = firebaseAuth.currentUser.uid;
	if (!UID) return false;

	// Set the email against user account
	return () =>
		firebaseAuth
			.currentUser.updateEmail(profile.email)
			.then(() => {
				// Then update user in DB
				firebaseDatabase
					.ref()
					.child(`users/${UID}`)
					.update(profile);
			});
}

export function updatePassword(oldPassword, newPassword) {
	return () => {
		let user = firebaseAuth.currentUser;
		let cred = firebaseAuth.EmailAuthProvider.credential(user.email, oldPassword);
		user.reauthenticateAndRetrieveDataWithCredential(cred)
			.then(() => user.updatePassword(newPassword))
			.catch((error) => console.log(error));
	};
}

export function updateDioceses(dioceses) {
	let updates = {};
	updates[`/diocese`] = dioceses;
	return () => firebaseDatabase.ref().update(updates);
}

export function getTransactions() {
	return dispatch => {
		client.get(APPACUTIS_TRANSACTIONS)
			.then(response => {
				dispatch(getTransactionsCreator(response.data));
			})
			.catch(e => manageError(e, dispatch));
	};
}

function manageError(e, dispatch) {
	dispatch(loading(false));
	if (e.response) {
		// The request was made and the server responded with a status code
		// that falls out of the range of 2xx
		dispatch(error(e.response.data.message));
	} else {
		// Something happened in setting up the request that triggered an Error
		console.log('Error', e.message);
		dispatch(error(e.message));
	}
}

export function clear() {
	return {
		type: CLEAR
	};
}
