export const LOADING = "LOADING_PRODUCTS";
export const ERROR = "ERROR";
export const SUCCESS = "SUCCESS";
export const CLEAR = "CLEAR";

export function loading(loading = true) {
	return {
		type: LOADING,
		data: loading
	};
}

export function error(error) {
	return {
		type: ERROR,
		data: error
	};
}

export function success() {
	return {
		type: SUCCESS,
	};
}

export function clear() {
	return {
		type: CLEAR
	};
}

