import React from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Button, Alert} from 'reactstrap'
import ModalRegistrationInput from './ModalRegistrationInput';
import {Client} from '../../redux/utils/index';

/*
* React class in which the modal view of the registration page is implemented
* */
export default class ModalRegistration extends React.Component{

  constructor(props){
    super(props);
    this.validateAndSend = this.validateAndSend.bind(this);
    this.toggleAlert = this.toggleAlert.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.getValue = this.getValue.bind(this);
    this.client = new Client();
    this.state = {
      fields:{
        firstName: {
          value: "",
          isValid: false,
        },
        lastName: {
          value: "",
          isValid: false
        },
        username: {
          value: "",
          isValid: false
        },
        email:{
          value: "",
          isValid: false
        },
        password: {
          value: "",
          isValid: false
        },
        confirm: {
          value: "",
          isValid: false
        }
      },
      openAlert: false,
      colorAlert: "success",
      messageAlert:"",
      requiredFields: "* Required fields",
      otherFields: "** Other fields on the profile page"
    }
  }

  /*
  * Function that manages the closing of the modal and resets the status of the alert
  * */
  closeModal(){

    if(this.state.openAlert){
      this.toggleAlert();
      this.setState({
        colorAlert: "success",
        messageAlert: ""
      });
    }

    this.props.toggleSignUpModal()
  }

  /*
  * Toggle function for the Alert component
  * */
  toggleAlert(){
    this.setState({
      openAlert: !this.state.openAlert
    });
  }

  /*
  * Callback function, called by every single field to update the status of the parent component.
  * In particular, they update the value and validity of the value of the inputs.
  *
  * Input parameters:
  *   id: String, id of the input field
  *   value: String, value of the input field
  *   isValid: Boolean, declare if value of input field is valid
  * */
  getValue(id, value, isValid=false){

      this.setState({
        fields:{...this.state.fields, [id]:{
            value: value,
            isValid: isValid
          }}
      });
  }

  /*
  * Validation and data sending to the server
  * */
  validateAndSend(){

    let sendData = true;
    let empty = 0;
    let emptyFields = [];
    let message = "";
    let password = this.state.fields["password"].value;
    let confirmPassword = this.state.fields["confirm"].value;

    Object.keys(this.state.fields).map((key)=>{
      if(this.state.fields[key].value === "" || !this.state.fields[key].isValid){
        empty++;
        emptyFields.push(key.toUpperCase());
      }
    });

    if(empty > 0){
      if(empty===1)
        message = "The field "+emptyFields.join(" ")+ " is empty or incorrect!";
      else
        message = "The fields "+emptyFields.join(", ")+ " are empty or incorrect!";

      this.setState({
        colorAlert: "danger",
        messageAlert: message
      });

      if(!this.state.openAlert){
        this.toggleAlert();
      }

      sendData = false;
    }else if(password !== confirmPassword){

      this.setState({
        colorAlert: "danger",
        messageAlert: "The entered passwords are different!"
      });

      if(!this.state.openAlert){
        this.toggleAlert();
      }

      sendData = false;
    }

    if(sendData){
      let newUser = {};

      this.toggleAlert();
      this.setState({
        messageAlert: "",
      });

      Object.keys(this.state.fields).map((key)=>{
        newUser[key] = this.state.fields[key].value;
      });

      this.client.signup(newUser).then((response) => {
        this.setState({
          colorAlert: "success",
          messageAlert: "Registration successful!",
        });
        console.log("Response: "+response.status+" - status code: "+response.statusCode);
      }).catch((error) => {

        this.setState({
          colorAlert: "danger",
          messageAlert: "Registration could not be completed!"
        });

        if(error.response)
          console.log("Response: "+error.response.statusText+" - status code: "+error.response.status);
      });
    }
  }

  /*
  * Rendering function
  * */
  render(){

    return(
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggleSignUpModal} className={"modal-signup-page"}>
        <ModalHeader className={"modal-header-signup-page"}>
          <p className={"modal-header-signup-page-title"}>{this.props.title}</p>
          <p className={"modal-header-signup-page-message"}>{this.props.message}</p>
        </ModalHeader>
        <ModalBody className={"modal-body-signup-page"}>
          <Form>
            <FormGroup>
              <Alert className={"alert-signup-page"} isOpen={this.state.openAlert} color={this.state.colorAlert} toggle={this.toggleAlert} >{this.state.messageAlert}</Alert>
              <ModalRegistrationInput
                addon={"prepend"}
                icon={"icon-user"}
                type={"text"}
                name={"firstName"}
                id={"firstName"}
                placeholder={"First Name"}
                required={true}
                getValue={this.getValue}
                className={!this.state.fields["firstName"].isValid?"modal-input-div-invalid":""}
                value={this.state.fields["firstName"].value}
              />
              <ModalRegistrationInput
                addon={"prepend"}
                icon={"icon-user"}
                type={"text"}
                name={"lastName"}
                id={"lastName"}
                placeholder={"Last name"}
                required={true}
                getValue={this.getValue}
                className={!this.state.fields["lastName"].isValid?"modal-input-div-invalid":""}
                value={this.state.fields["lastName"].value}
              />
              <ModalRegistrationInput
                addon={"prepend"}
                icon={"icon-people"}
                type={"text"}
                name={"username"}
                id={"username"}
                placeholder={"Username"}
                required={true}
                getValue={this.getValue}
                className={!this.state.fields["username"].isValid?"modal-input-div-invalid":""}
                value={this.state.fields["username"].value}
              />
              <ModalRegistrationInput
                addon={"prepend"}
                icon={"icon-envelope"}
                type={"email"}
                name={"email"}
                id={"email"}
                placeholder={"eMail"}
                required={true}
                getValue={this.getValue}
                className={!this.state.fields["email"].isValid?"modal-input-div-invalid":""}
                value={this.state.fields["email"].value}
              />
              <ModalRegistrationInput
                addon={"prepend"}
                icon={"icon-lock"}
                type={"password"}
                name={"password"}
                id={"password"}
                placeholder={"Password"}
                required={true}
                getValue={this.getValue}
                className={!this.state.fields["password"].isValid?"modal-input-div-invalid":""}
                value={""}
              />
              <ModalRegistrationInput
                addon={"prepend"}
                icon={"icon-lock"}
                type={"password"}
                name={"confirm"}
                id={"confirm"}
                placeholder={"Confirm password"}
                required={true}
                getValue={this.getValue}
                className={!this.state.fields["confirm"].isValid?"modal-input-div-invalid":""}
                password={this.state.fields["password"].isValid?this.state.fields["password"].value:""}
                value={""}
              />
              <p className={"modal-body-signup-page-info"}>{this.state.requiredFields}</p>
              <p className={"modal-body-signup-page-info"}>{this.state.otherFields}</p>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter className={"modal-footer-signup-page"}>
          <Button color="primary" onClick={this.validateAndSend}>Submit</Button>{' '}
          <Button color="secondary" onClick={this.closeModal}>Cancel</Button>
        </ModalFooter>
      </Modal>
    );
  }
}