export const SUCCESS = "SUCCESS_USER";
export const ERROR = "ERROR_USER";
export const CLEAR = "CLEAR_USER";
export const LOADING = "LOADING_USER";

export const GET_INVOICES = "GET_INVOICES_USER";
export const UPDATE_INVOICE = "UPDATE_INVOICE_USER";
export const GET_OPEN_INVOICE = "GET_OPEN_INVOICE";
export const REMOVE_INVOICE = "REMOVE_INVOICE_USER";

export const GET_TRANSACTIONS = "GET_TRANSACTIONS";

export function loading(loading = true) {
	return {
		type: LOADING,
		data: loading
	};
}

export function error(error) {
	return {
		type: ERROR,
		data: error
	};
}


export function success() {
	return {
		type: SUCCESS,
	};
}

export function clear() {
	return {
		type: CLEAR
	};
}

export function saveInvoices(invoices) {
	return {
		type: GET_INVOICES,
		data: invoices
	};
}

export function getOpenInvoiceCreator(invoice) {
	return {
		type: GET_OPEN_INVOICE,
		data: invoice
	};
}

export function updateStorageLink(id, type, url) {
	return {
		type: UPDATE_INVOICE,
		data: {id, type, url}
	};
}
export function removeInvoice(shop, invoice) {
	return {
		type: REMOVE_INVOICE,
		data: {shop, invoice}
	};
}
export function getTransactionsCreator(transactions) {
	return {
		type: GET_TRANSACTIONS,
		data: transactions
	};
}