import {Col, Row} from "reactstrap";
import NTMTable from "./Table/NTMTable";
import React from "react";

export default function Table({title, header, body, filterable}) {
	return <div style={{marginBottom: "1.5em"}} key={title}>
		<Row style={{marginBottom: "1.5em"}} className="text-center h4">
			<Col>{title.toUpperCase()}</Col>
		</Row>
		<NTMTable
			header={header}
			body={body}
			filterable={filterable}
		/>
	</div>;
}
