import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";

console.log(process.env.REACT_APP_ENVIRONMENT);

let config = {
	apiKey: "AIzaSyA2wiUaVvvKXI9mUlAbX9zhHgvwJXaRW3o",
	authDomain: "tucum-c41a2.firebaseapp.com",
	databaseURL: process.env.REACT_APP_ENVIRONMENT === 'test' || process.env.REACT_APP_ENVIRONMENT === 'local' ? "https://tucum-c41a2-test.firebaseio.com" : "https://tucum-c41a2.firebaseio.com",
	projectId: "tucum-c41a2",
	storageBucket: "tucum-c41a2.appspot.com",
	messagingSenderId: "32059313951"
};
firebase.initializeApp(config);

export const firebaseDatabase = firebase.database();
export const firebaseAuth = firebase.auth();
export const firebaseStorage = firebase.storage();
export const SERVER_TIMESTAMP = firebase.database.ServerValue.TIMESTAMP;
