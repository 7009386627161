import React, {useEffect, useState} from "react";
import NTMLoadingModal from "../../components/Modal/NTMLoadingModal";
import {ErrorDialog, SuccessDialog, ConfirmDialog} from "@nextome/messages";
import NTMForm from "@nextome/nxtform";
import {Client} from "../../redux/utils";
import {SETTINGS} from "../../redux/utils/Client";
import NTMButton from "../../components/Buttons";
import {firebaseAuth} from "../../firebase";

let client = new Client();

export default function SettingsPage() {

	let [settings, setSettings] = useState({});
	let [modal, setModal] = useState(null);

	let manageError = e => {
		let error;
		if (e.response) {
			error = e.response.data.message;
		} else {
			error = e.message;
		}
		setModal(
			<ErrorDialog title={"Errore"} message={error} onClose={closeModal}/>
		);
	};

	const getSettings = () => client.get(SETTINGS)
		.then(response => {
			setSettings(response.data);
			setModal(null);
		})
		.catch(manageError);

	useEffect(() => {
		setModal(<NTMLoadingModal/>);
		getSettings();
	}, []);

	const closeModal = () => setModal(null);

	const onSubmit = (values) => {
		setModal(
			<ConfirmDialog
				message={`Vuoi salvare le modifiche?`}
				onCancel={closeModal}
				onConfirm={() => {
					console.log(values);
					setModal(<NTMLoadingModal/>);
					client.update(values, SETTINGS)
						.then(() => {
							setModal(
								<SuccessDialog
									title={"Operazione eseguita"}
									onClose={closeModal}
								/>
							);
							getSettings();
						})
						.catch(manageError);
				}}
			/>
		);
	};

	if (!settings) return <div/>;
	let initialValues = Object.assign({}, settings);

	for (let key in initialValues) {
		if (initialValues.hasOwnProperty(key)) {
			if (RegExp('^\\d+$').test(initialValues[key])) {
				initialValues[key] = Number.parseInt(initialValues[key]);
			}
		}
	}

	return (
		<div className="animated fadeIn" style={{padding: "1rem"}}>
			{modal}
			<NTMForm
				initialValues={initialValues}
				onSubmit={onSubmit}
				cols={2}
				saveButton={"top"}
				formData={[
					{
						type: "number",
						name: "ca",
						label: "Commissione APPAcutis (ca) iva esclusa",
						prepend: "€",
						props: {
							step: 0.01
						}
					},
					{
						type: "number",
						name: "cp",
						label: "Commissione Partner (cp) iva esclusa",
						prepend: "€",
						props: {
							step: 0.01
						}
					},
					{
						type: "number",
						name: "qss",
						label: "Quota Spese Sistema (qss) sotto i 10€ iva inclusa",
						prepend: "€",
						props: {
							step: 0.01
						}
					},
					{
						type: "number",
						name: "nexiFee",
						label: "Fee percentuale di Nexi",
						prepend: "%",
						props: {
							step: 0.01
						}
					},
					{
						type: "number",
						name: "qscr",
						label: "Quota Spese Calcolo Ripartizione Prodotto (qscr)",
						props: {
							disabled: true
						},
						prepend: "€"
					},
					{
						type: "number",
						name: "itVat",
						label: "IVA",
						prepend: "%"
					},
					{
						type: "number",
						name: "fdsos",
						label: "Percentuale Fondo Sostegno",
						prepend: "%"
					},
					{
						type: "number",
						name: "fdg",
						label: "Percentuale Fondo di Garanzia (fdg)",
						prepend: "%"
					},
					{
						type: "number",
						name: "fdsol",
						label: "Percentuale Fondo di Solidarietà (fdsol)",
						props: {
							disabled: true
						},
						prepend: "%"
					},
					{
						type: "number",
						name: "tucumB",
						label: "Credito Beneficiario (in Tucum)"
					},
					{
						type: "number",
						name: "coffeePrice",
						label: "Prezzo per Offri un caffè",
						prepend: "€",
						props: {
							step: 0.1
						}
					},
					{
						type: "number",
						name: "startdl",
						label: "Prezzo di partenza per Donazione Libera",
						prepend: "€",
						props: {
							step: 0.1
						}
					},
					{
						type: "number",
						name: "lmdl",
						label: "Limite Minimo Donazione Libera",
						prepend: "€",
						props: {
							step: 0.1
						}
					},
					{
						type: "number",
						name: "lmaxdl",
						label: "Limite Massimo Donazione Libera",
						prepend: "€",
						props: {
							step: 0.1
						}
					},
					{
						type: "number",
						name: "externalFundMinDonation",
						label: "Limite Minimo Donazione Fondo esterno",
						prepend: "€",
						props: {
							step: 0.1
						}
					},
					{
						type: "number",
						name: "externalFundMaxDonation",
						label: "Limite Massimo Donazione Fondo Esterno",
						prepend: "€",
						props: {
							step: 0.1
						}
					},
					{
						type: "number",
						name: "externalFundTucumFee",
						label: "Fee per progetto Tucum in Donazione Fondo Esterno fino 10€",
						prepend: "€",
						props: {
							step: 0.1
						}
					},
					{
						type: "number",
						name: "thresholdFee",
						label: "Soglia dopo la quale si incrementa il qss",
						prepend: "€",
						props: {
							step: 0.01
						}
					},
					{
						type: "number",
						name: "sf",
						label: "Soglia Massima Fatturabile (iva inclusa)",
						prepend: "€"
					},
					{
						type: "number",
						name: "nfg",
						label: "Limite Fatture Giornaliero"
					},
					{
						type: "number",
						name: "maxProductPrice",
						label: "Limite Massimo prezzo cliente",
						prepend: "€",
					},
					{
						type: "number",
						name: "minDiscount",
						label: "Percentuale minima di sconto del partner",
						prepend: "%"
					},
					{
						type: "number",
						name: "card",
						label: "Prezzo card Beneficiario (iva esclusa)",
						prepend: "€",
						props: {
							step: 0.00001
						}
					},
					{
						type: "number",
						name: "mntPayCard",
						label: "N° mesi che il beneficiario può pagare in anticipo",
						props: {
							min: 0,
							max: 5
						}
					},
					{
						type: "textarea",
						name: "welcome",
						label: "Frase di benvenuto",
						props: {rows: 3}
					},
					{
						type: "textarea",
						name: "tnks",
						label: "Frase di ringraziamento",
						props: {rows: 3}
					},
					{
						type: "url",
						name: "privacyLink",
						label: "URL pagina \"Informativa Privacy\""
					},
					{
						type: "url",
						name: "termsLink",
						label: "URL pagina \"Termini e Condizioni\""
					},

					{
						type: "url",
						name: "aboutLink",
						label: "URL pagina \"About\""
					},
					{
						type: "url",
						name: "projectsLink",
						label: "URL pagina \"Progetti\""
					},
					{
						type: "url",
						name: "coffeeInfoLink",
						label: "URL pagina \"Offri un caffè\""
					},
					{
						type: "url",
						name: "discoverLink",
						label: "URL pagina \"Scopri di più\""
					},
					{
						type: "url",
						name: "tucumInfoLink",
						label: "URL pagina \"Progetto Tucum\" pagina donazione"
					},
				]}
			/>
			<NTMButton primary onClick={() => {


				firebaseAuth.sendPasswordResetEmail(firebaseAuth.currentUser.email)
					.then(() => {
						console.log("Password reset email sent!")
						// ..
					})
					.catch((error) => {
						const errorCode = error.code;
						const errorMessage = error.message;
						console.log(errorCode, errorMessage)
					});

			}}>Invia email di reset password</NTMButton>
		</div>
	);
}

