import React from 'react';
import moment from "moment/moment";
import NTMFormModal from "../../components/Modal/NTMFormModal";

export default class EditUserModal extends React.Component {

	render() {

		let {user, onClose, onSubmit} = this.props;
		const initialValues = Object.assign({}, user);

		if (initialValues.birthday) initialValues.birthday = moment(initialValues.birthday, "DD/MM/YYYY").format("YYYY-MM-DD");
		if (initialValues.createdAt) initialValues.createdAt = moment(initialValues.createdAt).format("YYYY-MM-DD");

		let formData = [
			{
				name: "firstName",
				type: "text",
				label: "Nome",
				props: {
					className: "form-control-plaintext",
					disabled: true
				}
			},
			{
				name: "lastName",
				type: "text",
				label: "Cognome",
				props: {
					className: "form-control-plaintext",
					disabled: true
				}
			},
			{
				name: "email",
				type: "text",
				label: "Email",
				props: {
					className: "form-control-plaintext",
					disabled: true
				}
			},
			{
				name: "birthday",
				type: "date",
				label: "Data di nascita",
				props: {
					className: "form-control-plaintext",
					disabled: true
				}
			},
			{
				name: "cityOfBirth",
				type: "text",
				label: "Luogo di nascita",
				props: {
					className: "form-control-plaintext",
					disabled: true
				}
			}, {
				name: "city",
				type: "text",
				label: "Città",
				props: {
					className: "form-control-plaintext",
					disabled: true
				}
			}, {
				name: "phone",
				type: "text",
				label: "Telefono",
				props: {
					className: "form-control-plaintext",
					disabled: true
				}
			},
			{
				name: "createdAt",
				label: "Data iscrizione",
				type: "date",
				props: {
					className: "form-control-plaintext",
					disabled: true
				}
			},

		];

		if (user.type !== 4) {
			formData.push({
					name: "enabled",
					label: "Utente abilitato",
					options: [
						{
							value: true,
							name: "Sì"
						}, {
							value: false,
							name: "No"
						}]
				},
				{
					name: "type",
					label: "Tipologia",
					options: [
						{
							value: 1,
							name: "Donatore"
						}, {
							value: 2,
							name: "Partner"
						}, {
							value: 3,
							name: "Supervisore"
						}, {
							value: 4,
							name: "Beneficiario"
						}, {
							value: 5,
							name: "Amministr. Diocesi"
						}, {
							value: 10,
							name: "Superuser"
						}]
				});
		}


		return (
			<NTMFormModal
				initialValues={initialValues}
				formData={formData}
				onSubmit={(values) => {
					// console.log(values);
					if (values.birthday) values.birthday = moment(values.birthday, "YYYY-MM-DD").format("DD/MM/YYYY");
					values.createdAt = user.createdAt;
					if (values.docNumber) values.docNumber = values.docNumber.toString();
					if (values.enabled && values.enabled === 'true') values.enabled = true;
					else if (values.enabled && values.enabled === 'false') values.enabled = false;
					if (!values.type) values.type = user.type;
					onSubmit(values);
				}}
				onClose={onClose}
				title={"Modifica " + user.email}
			/>
		);
	}
}
