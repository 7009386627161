import React, {useEffect, useState} from "react";
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import {Client} from "../redux/utils";
import {
	DIOCESE_CLOSED_INVOICES,
	DIOCESE_INVOICES,
	SHOPS,
	get, DIOCESE
} from "../redux/utils/Client";
import {useSnackbar} from "notistack";
import NTMButton from "../components/Buttons";
import SweetAlert from "react-bootstrap-sweetalert";
import {firebaseStorage} from "../firebase";
import {Link, useParams} from "react-router-dom";
import Section from "../components/Section";
import Table from "../components/Table";
import {Col, Row} from "reactstrap";
import {listDioceses} from "../redux/cards/cardsActions";
import {MoneyCell, UserCell} from "../components/Table/tableCells";
import EditUserModal from "./Users/EditUserModal";
import {updateUser} from "../redux/users/userActions";
import {DateRangePicker} from "react-dates";

let client = new Client();

export default function HangingFundPage() {

	let [modal, setModal] = useState();
	let [openInvoice, setOpenInvoice] = useState();
	let [lastInvoiceNumber, setLastInvoiceNumber] = useState();
	let [invoices, setInvoices] = useState([]);
	let [tab, setTab] = useState("invoices");
	let [startDate, setStartDate] = useState(moment().year(2021).dayOfYear(1));
	let [endDate, setEndDate] = useState(moment());
	let [focusedInput, setFocusedInput] = useState();
	let [erogationsOverview, setErogationsOverview] = useState({});
	let [shops, setShops] = useState([]);
	let users = useSelector(store => store.users.list);
	const {enqueueSnackbar} = useSnackbar();
	const dioceses = useSelector(store => store.cards.dioceses);
	let {hanging} = useParams();
	const fund = dioceses.find(d => d.id == hanging) || {};
	const dispatch = useDispatch();

	useEffect(() => {
		getInvoices();
		getOpenInvoice();
		getErogationsOverview();
		dispatch(listDioceses());
		get(SHOPS, {params: {did: hanging}}).then(data => setShops(data));
	}, []);
	useEffect(() => {
		if (!focusedInput) {
			getErogationsOverview();
		}
	}, [startDate, endDate]);

	function manageError(e) {
		enqueueSnackbar(client.getErrorMessage(e), {variant: "error"});
	}

	function closeModal() {
		setModal(null);
	}

	function getOpenInvoice() {
		client.get(DIOCESE_INVOICES, hanging)
			.then(({data}) => {
				data.startDate = data.startDate && moment(data.startDate).format("DD/MM/YYYY");
				setOpenInvoice(data);
			})
			.catch(manageError);
	}

	function getErogationsOverview() {
		get(`${DIOCESE}/${hanging}/overview`, {
			params: {
				start: startDate.format("yyyy-MM-DD"),
				end: endDate.format("yyyy-MM-DD"),
				withShops: false
			}
		}).then(data => setErogationsOverview(data));
	}

	function getInvoices() {
		client.get(DIOCESE_CLOSED_INVOICES, hanging)
			.then(async ({data}) => {
				for (let i of data) {
					i.invoiceDate = i.invoiceDate && moment(i.invoiceDate).format("DD/MM/YYYY");
					if (i.link)
						i.link = await firebaseStorage.refFromURL(i.link).getDownloadURL();

				}
				data.sort((a, b) => sort(a.invoiceNumber, b.invoiceNumber));
				let lastInvoiceNumber = 0;
				if (data.length > 0) {
					let year = data[0].invoiceNumber.split("/")[1];
					if (parseInt(year) === new Date().getFullYear())
						lastInvoiceNumber = parseInt(data[0].invoiceNumber.split("/")[0]);
				}

				setLastInvoiceNumber(lastInvoiceNumber);
				setInvoices(data);
			})
			.catch(manageError);
	}

	function closeInvoice(invoiceId, invoiceNumber) {
		client.save(null, `${DIOCESE_INVOICES}/${invoiceId}/${invoiceNumber}`)
			.then(() => {
				getInvoices();
				getOpenInvoice();
			})
			.catch(manageError)
			.finally(closeModal);
	}

	function sort(a, b) {
		let splitteda = a.split("/");
		let splittedb = b.split("/");
		if (splitteda[1] === splittedb[1]) {
			return splittedb[0] - splitteda[0];
		} else return splittedb[1] - splitteda[1];
	}

	function userFilter(filter, row) {
		let user = users.find(u => row[filter.id] === u.partner_venue);
		let username = user ? `${user.firstName} ${user.lastName}` : "Sconosciuto";
		return username && username.toLowerCase().includes(filter.value && filter.value.toLowerCase());
	}

	return (
		<div className="animated fadeIn card">
			{modal}
			<Row className="text-center display-4" style={{paddingBottom: "1rem"}}>
				<Col>{fund.name}</Col>
			</Row>
			{fund.id && <Section
				title={fund.fiscalName}
				data={[
					{
						label: "Indirizzo",
						value: fund.address
					},
					{
						label: "CAP",
						value: fund.cap
					},
					{
						label: "Città",
						value: fund.city
					},
					{
						label: "Email contatto",
						value: fund.email
					},
					{
						label: "Telefono",
						value: fund.tel
					},
					{
						label: "Indirizzo PEC",
						value: fund.pec
					},
					{
						label: "Cod. Fiscale",
						value: fund.fiscalCode
					},
					{
						label: "Partita IVA",
						value: fund.piva
					},
					{
						label: "Cod. Fatturaz. Elettr.",
						value: fund.recipientCode
					}]
				}
			/>}
			<Row style={{padding: "1rem"}}>
				<NTMButton secondary={tab === "invoices"} primary={tab !== "invoices"}
				           onClick={() => setTab("invoices")}>Fatture</NTMButton>
				<NTMButton secondary={tab === "shops"} primary={tab !== "shops"}
				           onClick={() => setTab("shops")}>Negozi</NTMButton>

			</Row>
			{openInvoice && tab === "invoices" &&
			<Table
				title="Fatture aperte"
				header={[
					{
						Header: "Data apertura fattura",
						Cell: props => <div>{moment(props.original.startDate).format("DD/MM/YYYY")}</div>
					},
					{
						Header: "Descrizione",
						Cell: props => <div>{
							props.original.invoiceBody.map((i, k) => <div
								key={k}>{i.description.replace("nel", "per il")}</div>)
						}</div>
					},
					{
						Header: "Totale",
						Cell: props => <div>{`${props.original.totalInvoice} €`}</div>
					},
					{
						Header: 'Chiudi Fattura',
						Cell: props => <NTMButton primary
						                          onClick={() => setModal(
							                          <SweetAlert
								                          showCancel
								                          confirmBtnText={"Conferma"}
								                          confirmBtnBsStyle={"danger"}
								                          cancelBtnText={"Annulla"}
								                          cancelBtnBsStyle={"default"}
								                          title={"Confermi?"}
								                          onConfirm={() => {
									                          closeInvoice(props.original.invoiceId, lastInvoiceNumber + 1);
								                          }}
								                          onCancel={closeModal}>
								                          {`Il numero d'ordine sarà ${lastInvoiceNumber + 1}/${new Date().getFullYear()}`}
							                          </SweetAlert>
						                          )}
						>
							{"Chiudi Fattura"}
						</NTMButton>
					}
				]}
				body={openInvoice}
			/>
			}
			{tab === "invoices" &&
			<Table
				title={"Fatture emesse"}
				header={[
					{
						Header: "Numero d'ordine",
						accessor: 'invoiceNumber',
						sortMethod: sort
					},
					{
						Header: "Data fattura",
						accessor: 'invoiceDate',
					},
					{
						Header: "Totale",
						accessor: 'value',
						Cell: props => <MoneyCell value={props.original.value}/>
					},
					{
						Header: 'Scarica',
						Cell: props => <div>
							{
								props.original.link && <a className={"btn"} href={props.original.link}>
									<i className="fa fa-file-pdf-o" aria-hidden="true"/>
								</a>
							}
							{
								props.original.eInvoiceLink && <a className={"btn"} href={props.original.eInvoiceLink}>
									<i className="fa fa-file-excel-o" aria-hidden="true"/>
								</a>
							}

						</div>
					}
				]}
				body={invoices}
			/>
			}
			{
				tab === "shops" && <>
					<DateRangePicker
						style={styles.ctr}
						startDate={startDate} // momentPropTypes.momentObj or null,
						startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
						endDate={endDate} // momentPropTypes.momentObj or null,
						endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
						onDatesChange={({startDate, endDate}) => {
							setStartDate(startDate);
							setEndDate(endDate);
						}} // PropTypes.func.isRequired,
						focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
						onFocusChange={focusedInput => {
							setFocusedInput(focusedInput);
						}} // PropTypes.func.isRequired
						minimumNights={0}

						startDatePlaceholderText={"Inizio"}
						endDatePlaceholderText={"Fine"}
						displayFormat={"DD/MM/YYYY"}
						isOutsideRange={(o) => o.isAfter(moment(), 'day')}
						small={true}
					/>
					<Section
						title={"Resoconto erogazioni"}
						data={[
							{
								label: "Totale crediti",
								value: MoneyCell({value: erogationsOverview.priceToVendor})
							},
							{
								label: "Totale prezzo di vendita",
								value: MoneyCell({value: erogationsOverview.priceToClient})
							}
						]}
					/>
					<Table
						title={"Negozi"}
						header={
							[{
								Header: 'Nome',
								accessor: 'name' // String-based value accessors!
							}, {
								Header: 'Città',
								accessor: 'city',
							}, {
								Header: 'Indirizzo',
								accessor: 'address',
							}, {
								Header: 'Partner',
								accessor: "id",
								Cell: props => <UserCell
									user={users.find(u => u.partner_venue === props.original.id)}
									onClick={() => {
										setModal(
											<EditUserModal
												user={users.find(u => u.partner_venue === props.original.id)}
												onSubmit={(values) => {
													dispatch(updateUser(values));
													closeModal();
												}}
												onClose={closeModal}
											/>);
									}}
								/>,
								filterMethod: userFilter
							}, {
								Header: 'Fondo',
								accessor: 'fund',
								Cell: props => <div>{props.original.fundName || "-"}</div>
							}, {
								Header: 'Dettagli',
								Cell: props => <Link
									to={{
										pathname: `/shops/${props.original.id}`,
										state: {fromDashboard: true}
									}}
								>
									<i className="fa fa-external-link" aria-hidden="true"/>
								</Link>,
								filterable: false
							}]}
						body={shops}
						filterable
					/>
				</>
			}
		</div>
	);
}

const styles =
	{
		ctr: {
			marginTop: "auto",
			marginBottom: "auto",
			marginLeft: "1rem"
		}
	};


