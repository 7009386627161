import {Col, Row} from "reactstrap";
import React from "react";

export default function Section({title = "", data}) {
	return <div className="animated fadeIn card" style={{padding: "0.5rem"}}>
		<div style={{marginBottom: "1.5em"}}>
			<Row style={{marginBottom: "1.5em"}} className="text-center h4">
				<Col>{title.toUpperCase()}</Col>
			</Row>
			<div style={{
				display: "flex",
				alignItems: "flex-start",
				justifyContent: "flex-start",
				flexFlow: "row wrap",
				alignContent: "flex-start"
			}}>
				{data.map((e) => {
						return (
							<Row key={e.name} style={{marginBottom: "1em"}} className={`col-12 col-md-6`}>
								<Col><strong>{e.label}</strong></Col>
								<Col>{e.value}</Col>
							</Row>);
					}
				)}
			</div>
		</div>
	</div>;
}
