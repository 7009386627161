import React from 'react';
import NTMButton from "../Buttons";

import Papa from "papaparse";

export default class CSVExporter extends React.Component {

	downloadFile = () => {
		let {data, name} = this.props;
		let csv = Papa.unparse(data, {delimiter: ";"});
		if(!name.endsWith(".csv")) name += ".csv";
		const element = document.createElement("a");
		const file = new Blob([csv], {type: 'text/plain'});
		element.href = URL.createObjectURL(file);
		element.download = name;
		document.body.appendChild(element); // Required for this to work in FireFox
		element.click();
	};

	render() {
		return (
			<NTMButton primary onClick={this.downloadFile}>
				<i className="fa fa-table" aria-hidden="true"/>
				{" "}
				{this.props.title || "Scarica lista " + this.props.name}
			</NTMButton>
		);
	}
}