import React from "react";
import ProfilePage from "./ProfilePage";
import {connect} from "react-redux";
import {
	closeInvoice,
	getInvoices,
	getOpenInvoice,
	getTransactions,
	clear,
	updateUser
} from '../../redux/users/userActions';
import NTMButton from "../../components/Buttons";
import NTMLoadingModal from "../../components/Modal/NTMLoadingModal";
import {ErrorDialog, SuccessDialog, ConfirmDialog} from "@nextome/messages";
import SweetAlert from "react-bootstrap-sweetalert";
import moment from "moment";
import EditUserModal from "../Users/EditUserModal";

/*Function that associates the Profile status saved in the store, with the profile variable passed as props to profile page*/
function mapStateToProps({users}) {
	return {
		profile: users.data,
		invoices: users.invoices,
		loading: users.loading,
		success: users.success,
		error: users.error,
		users: users.list,
		transactions: users.transactions
	};
}

const mapDispatchToProps = {
	getInvoices, getOpenInvoice, closeInvoice, clear, getTransactions, updateUser
};

class ProfileContainer extends React.Component {

	state = {};

	componentDidMount() {
		this.props.getInvoices();
		this.props.getOpenInvoice();
		this.props.getTransactions();
	}

	componentWillReceiveProps(props) {
		if (props.loading) {
			this.openModal(<NTMLoadingModal/>);
		} else if (props.error) {
			this.openModal(<ErrorDialog
				message={props.error}
				onClose={() => {
					this.props.clear();
					this.closeModal();
				}}
			/>);
		} else if (props.success) {
			this.openModal(
				<SuccessDialog
					title={"Operazione eseguita"}
					onClose={() => {
						this.props.clear();
						this.closeModal();
					}}
				/>
			);
		}
	}

	closeModal = () => {
		this.setState({
			modal: null
		});
	};

	openModal = (modal) => {
		this.setState({
			modal
		});
	};

	moneyCell = value => <div>{value ? value.toFixed(2) : "-"} €</div>;

	timestampCell = value => <div>{value ? moment(value).format("DD/MM/YYYY HH:mm") : "-"}</div>;

	UserCell = (donor) => {
		let user = this.props.users.find(u => donor === u.uid);

		if (!user) return <div>{"Sconosciuto"}</div>;
		let username = `${user.firstName} ${user.lastName}`;

		return (
			<div
				className={"userCell"}
				onClick={() => {
					this.openModal(
						<EditUserModal
							user={user}
							onSubmit={(values) => {
								this.props.updateUser(values);
								this.closeModal();
							}}
							onClose={this.closeModal}
						/>);
				}}>
				{username}
			</div>);
	};

	render() {

		let {invoices, profile, transactions} = this.props;
		let tables = [];
		let sections = [];

		if (invoices.closed) {
			let table = {
				title: "Fatture emesse",
				header: [
					{
						Header: "Numero d'ordine",
						accessor: 'invoiceNumber',
						sortMethod: (a, b) => {
							let splitteda = a.split("/");
							let splittedb = b.split("/");
							if (splitteda[1] === splittedb[1]) {
								return splittedb[0] - splitteda[0];
							} else return splittedb[1] - splitteda[1];
						}
					},
					{
						Header: "Data fattura",
						accessor: 'invoiceDate',
					},
					{
						Header: "Totale",
						accessor: 'value'
					},
					{
						Header: 'Scarica',
						Cell: props => <div>
							<a className={"btn"} href={props.original.link}><i className="fa fa-file-pdf-o"
							                                                   aria-hidden="true"></i></a>
							<a className={"btn"} href={props.original.eInvoiceLink}><i className="fa fa-file-excel-o"
							                                                           aria-hidden="true"></i></a>
						</div>
					}
				],
				body: invoices.closed
			};
			tables.push(table);
		}

		if (transactions) {
			transactions.sort((a, b) => b.timestamp - a.timestamp);
			let table = {
				title: "Ripartizioni",
				header: [
					{
						Header: "Donatore",
						accessor: "user",
						Cell: props => this.UserCell(props.original.user),
						sortMethod: (a, b) => {
							let a_u = a.firstName ? `${a.firstName} ${a.lastName}` : "sconosciuto";
							let b_u = b.firstName ? `${b.firstName} ${b.lastName}` : "sconosciuto";
							return a_u.toLocaleLowerCase().localeCompare(b_u.toLocaleLowerCase());
						}
					},
					{
						Header: "Dispositivo",
						accessor: "device"
					},
					{
						Header: "Data",
						accessor: "timestamp",
						Cell: props => this.timestampCell(props.original.timestamp)
					},
					{
						Header: "Tipo",
						accessor: 'type',
						Cell: props => props.original.type === 'externalFund' ? "f. esterno" : props.original.type === 'hangingFund' ? "f. sospeso" : props.original.type
					},
					{
						Header: "Destinazione",
						Cell: props => props.original.partner ? props.original.partnerName : props.original.diocese ? props.original.dioceseName : "-"
					},
					{
						Header: 'Tot',
						accessor: "totTransaction",
						Cell: props => this.moneyCell(props.original.totTransaction)
					},
					{
						Header: 'FdSos',
						accessor: "totSupport",
						Cell: props => this.moneyCell(props.original.totSupport)
					},
					{
						Header: 'FdSol',
						accessor: "totSolidarity",
						Cell: props => this.moneyCell(props.original.totSolidarity)
					},
					{
						Header: 'FdF',
						accessor: "totGuarantee",
						Cell: props => this.moneyCell(props.original.totGuarantee)
					},
					{
						Header: 'FdRac',
						accessor: "totCollection",
						Cell: props => this.moneyCell(props.original.totCollection)
					},
					{
						Header: 'Ripartita',
						accessor: 'dividedTimestamp',
						Cell: props => this.timestampCell(props.original.dividedTimestamp)
					}
				],
				body: transactions
			};
			tables.push(table);
		}

		let openInvoice = invoices.open;

		if (openInvoice && openInvoice.startDate) {
			sections.push({
				title: "Fattura Aperta",
				data: [
					{
						label: "Inizio periodo fatturazione",
						value: openInvoice.startDate
					},
					{
						label: "Donazioni effettuate",
						value: openInvoice.invoiceBody && openInvoice.invoiceBody.quantity
					},
					{
						label: "Totale Fattura Ivato",
						value: openInvoice.invoiceBody && openInvoice.totalInvoice
					},
					{
						label: <NTMButton primary
						                  onClick={() => this.openModal(
							                  <SweetAlert
								                  input
								                  showCancel
								                  confirmBtnText={"Conferma"}
								                  confirmBtnBsStyle={"danger"}
								                  cancelBtnText={"Annulla"}
								                  cancelBtnBsStyle={"default"}
								                  title={"Confermi?"}
								                  onConfirm={(value) => {
									                  this.props.closeInvoice(openInvoice.invoiceId, value);
								                  }}
								                  onCancel={this.closeModal}
							                  >
								                  {"Inserisci il numero d'ordine"}
							                  </SweetAlert>
						                  )}
						>
							{"Chiudi Fattura"}
						</NTMButton>
					}
				]
			});
		}

		return <React.Fragment>
			{this.state.modal}
			<ProfilePage
				title={profile.firstName + " " + profile.lastName}
				image={require("../../img/utente.svg")}
				tables={tables}
				sections={sections}
			/>
		</React.Fragment>;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileContainer);
