export const LOADING = "SETTINGS_LOADING";
export const ERROR = "SETTINGS_ERROR";
export const SUCCESS = "SETTINGS_SUCCESS";
export const CLEAR = "SETTINGS_CLEAR";
export const GET_SETTINGS = "GET_SETTINGS";

export function loading(loading = true) {
	return {
		type: LOADING,
		data: loading
	};
}

export function error(error) {
	return {
		type: ERROR,
		data: error
	};
}

export function success(settings) {
	return {
		type: SUCCESS,
		data: settings
	};
}

export function getSettings(settings) {
	return {
		type: GET_SETTINGS,
		data: settings
	};
}

export function clear() {
	return {
		type: CLEAR
	};
}

