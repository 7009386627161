import React, {useEffect, useState} from "react";
import {Card, CardBody, Col, Row} from "reactstrap";
import NTMTable from "../../components/Table/NTMTable";
import EditUserModal from "../Users/EditUserModal";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {updateUser} from "../../redux/users/userActions";
import NTMButton from "../../components/Buttons";
import NTMFormModal from "../../components/Modal/NTMFormModal";
import {useQuery} from "react-query";
import {BANK_ACCOUNTS, Client, SHOPS} from "../../redux/utils/Client";
import {ConfirmDialog} from "@nextome/messages";
import {MoneyCell, UserCell} from "../../components/Table/tableCells";
import CSVExporter from "../../components/Table/CSVExporter";

let client = new Client();

export default function CardPage() {

	let [modal, setModal] = useState();
	let [filter, setFilter] = useState([])
	let [filteredData, setFilteredData] = useState([])
	let users = useSelector(store => store.users.list);

	let {
		data: list,
		isLoading
	} = useQuery("bank-accounts", () => client.get(BANK_ACCOUNTS).then(response => response.data));
	let {
		data: shops
	} = useQuery("shops", () => client.get(SHOPS).then(response => response.data));
	let dispatch = useDispatch();

	useEffect(() => {
		let filtered = shops?.filter(s => s.type !== 1001 && s.type !== 10).slice()
		filter.forEach(f => filtered = filtered?.filter(s => (s[f.id] || "").toString().toLowerCase().includes(f.value.toLowerCase())))
		setFilteredData(filtered)
	}, [filter, shops])

	let closeModal = () => setModal(null);

	let userFilter = (filter, row) => {
		let user = users.find(u => row[filter.id] === u.partner_venue);
		let username = user ? `${user.firstName} ${user.lastName}` : "Sconosciuto";
		return username && username.toLowerCase().includes(filter.value && filter.value.toLowerCase());
	};

	let edit = shop => setModal(
		<NTMFormModal
			initialValues={{fund: shop.fundId}}
			formData={[
				{
					type: "select",
					name: "fund",
					label: "Fondo associato",
					options: [
						{
							name: "Tucum",
							value: -1
						},
					].concat(list.map(ba => {
						return {name: `${ba.header} - ${ba.description}`, value: ba.id};
					}))
				},
			]}
			onSubmit={values => {

				setModal(<ConfirmDialog
					title={"Confermi?"}
					message={values.fund > 0 ? `Stai associando il negozio ${shop.name} al fondo ${list.find(b => b.id === values.fund).header}` : `Stai riassegnando il negozio ${shop.name} al Progetto Tucum`}
					onCancel={setModal(null)}
					onConfirm={() => {
						if (values.fund > 0) client.update({}, `shops/${shop.id}/fund/${values.fund}`);
						else client.delete({id: ""}, `shops/${shop.id}/fund`);
						setModal(null);
					}}
				/>);
			}}
			onClose={closeModal}
			title={"Associa fondo"}
		/>
	);

	let shopsToCsv = () => {
		return filteredData?.map(s => {
			let user = users.find(u => u.partner_venue === s.id)
			let username = "Sconosciuto";
			if (user) username = `${user.firstName} ${user.lastName}`;

			return {
				'Nome':s.name,
				'Città':s.city,
				'Indirizzo':s.address,
				'Partner': username,
				'Fondo': s.fundName,
				'Totale fatturato': s.totInvoices,
				'Totale erogato': s.totOpenInvoices
			}
		});
	}

	return (
		<div className="animated fadeIn">
			{modal}
			<Row>
				<Col>
					<Card>
						<CardBody>
							<Row>
								<Col>Totale fatturato selezione: {filteredData?.map(s => s.totInvoices || 0).reduce((a,b)=>a+b, 0.0).toFixed(2)}€</Col>
								<Col>Totale erogato selezione: {filteredData?.map(s => s.totOpenInvoices || 0).reduce((a,b)=>a+b, 0.0).toFixed(2)}€</Col>
							</Row>
						</CardBody>
					</Card>

				</Col>
			</Row>
			<Row><CSVExporter name={"negozi"} data={shopsToCsv()}/></Row>
			<Row>
				<Col>
					<NTMTable
						title={"Negozi Partner"}
						header={[
							{
								Header: 'Nome',
								accessor: 'name' // String-based value accessors!
							},
							{
								Header: 'Città',
								accessor: 'city',
							},
							{
								Header: 'Indirizzo',
								accessor: 'address',
							},
							{
								Header: 'Partner',
								accessor: "id",
								Cell: props => <UserCell
									user={users.find(u => u.partner_venue === props.original.id)}
									onClick={() => {
										setModal(
											<EditUserModal
												user={users.find(u => u.partner_venue === props.original.id)}
												onSubmit={(values) => {
													dispatch(updateUser(values));
													closeModal();
												}}
												onClose={closeModal}
											/>);
									}}
								/>,
								filterMethod: userFilter
							},
							{
								Header: 'Fondo',
								accessor: 'fundName',
								Cell: props => <div>{props.original.fundName || "-"} <NTMButton primary outline
								                                                                onClick={() => edit(props.original)}>
									<i className={"fa fa-pencil-square"}/>
								</NTMButton></div>
							},
							{
								Header: 'Totale fatturato',
								accessor: 'totInvoices',
								Cell: props => <MoneyCell value={props.original.totInvoices}/>
							},
							{
								Header: 'Totale erogato',
								accessor: 'totOpenInvoices',
								Cell: props => <MoneyCell value={props.original.totOpenInvoices}/>
							}, {
								Header: 'Dettagli',
								Cell: props => <Link
									to={{
										pathname: `/shops/${props.original.id}`,
										state: {fromDashboard: true}
									}}
								>
									<i className="fa fa-external-link" aria-hidden="true"/>
								</Link>,
								filterable: false
							}
							]}
						filterable
						body={shops ? shops.filter(s => s.type !== 1001 && s.type !== 10) : []}
						onFilteredChange={f => setFilter(f)}
					/>
				</Col>
			</Row>
		</div>
	);

};
