import React, {Component} from "react";
import {Col, Row} from "reactstrap";
import NTMTable from "../../components/Table/NTMTable";

/*
* This class renders the profile page using the ProfileInput and ProfileImageMenu components.
* */
export default class ProfilePage extends Component {

	Content = (data, cols = 2) => {
		return (
			<div style={{
				display: "flex",
				alignItems: "flex-start",
				justifyContent: "flex-start",
				flexFlow: "row wrap",
				alignContent: "flex-start"
			}}>
				{data.map((e) => {
						return (
							<Row key={e.name} style={{marginBottom: "1em"}} className={`col-12 col-md-${12 / cols}`}>
								<Col><strong>{e.label}</strong></Col>
								<Col>{e.value}</Col>
							</Row>);
					}
				)}
			</div>
		);
	};

	/*
	* Function that renders the profile page
	* */
	render() {

		let {image, title, sections, tables} = this.props;

		return (
			<div className="animated fadeIn card">
				{image &&
				<Row style={{marginTop: "2em"}}>
					<img src={image} className="rounded mx-auto d-block"/>
				</Row>
				}
				<Row className="text-center display-4">
					<Col>{title && title.toUpperCase()}</Col>
				</Row>
				{
					sections && sections.map(({title, data, cols=2}) =>
						<div style={{marginBottom: "1.5em"}} key={title}>
							<Row style={{marginBottom: "1.5em"}} className="text-center h4">
								<Col>{title && title.toUpperCase()}</Col>
							</Row>
							{
								this.Content(data, cols)
							}
						</div>
					)
				}
				{
					tables && tables.map(({title, header, body, filterable}) =>
						<div style={{marginBottom: "1.5em"}} key={title}>
							<Row style={{marginBottom: "1.5em"}} className="text-center h4">
								<Col>{title.toUpperCase()}</Col>
							</Row>
							<NTMTable
								header={header}
								body={body}
								filterable={filterable}
							/>
						</div>
					)
				}

			</div>
		);
	}
}
