import {
	LOADING,
	ERROR,
	SUCCESS,
	CLEAR,
	ADD_SHOP,
	EDIT_SHOP,
	REMOVE_SHOP,
	GET_SHOP_CATEGORIES, GET_INVOICES, UPDATE_INVOICE, REMOVE_INVOICE
} from "./shopsActionsCreator";
import {LOGOUT} from "../users/userActions";

const defaultState = {
	loading: false,
	list: [],
	categories: [],
	invoices: {}
};

export default function (state = defaultState, action) {
	switch (action.type) {
		case LOADING: {
			return {
				...state,
				loading: action.data
			};
		}
		case GET_SHOP_CATEGORIES: {
			return {
				...state,
				categories: action.data,
				loading: false
			};
		}
		case ADD_SHOP: {
			let list = [...state.list];
			list.push(action.data);
			return {
				...state,
				list,
				loading: false
			};
		}
		case REMOVE_SHOP: {
			let list = [...state.list];
			list = list.filter(l => l.id !== action.data.id);
			return {
				...state,
				list,
				loading: false
			};
		}
		case EDIT_SHOP: {
			let list = [...state.list];
			let index = list.findIndex(d => d.id === action.data.id);
			list[index] = action.data;
			return {
				...state,
				list,
				loading: false
			};
		}
		case ERROR: {
			return {
				...state,
				loading: false,
				error: action.data
			};
		}
		case SUCCESS: {
			return {
				...state,
				loading: false,
				success: true
			};
		}
		case CLEAR: {
			return {
				...state,
				loading: false,
				success: false,
				error: null
			};
		}
		case GET_INVOICES : {
			let invoices = Object.assign({}, state.invoices);
			invoices[action.data.shop] = action.data.list;
			return {
				...state,
				loading: false,
				success: true,
				invoices
			};
		}
		case UPDATE_INVOICE: {

			let invoices = Object.assign({}, state.invoices);
			let shop = invoices[action.data.shop];

			if (shop) {
				let index = shop.findIndex(d => d.invoiceNumber === action.data.id);
				if (shop[index])
					shop[index][action.data.type] = action.data.url;
				invoices[action.data.shop] = shop;
			}

			return {
				...state,
				invoices,
				loading: false
			};
		}
		case REMOVE_INVOICE: {
			let invoices = Object.assign({}, state.invoices);
			let shop = invoices[action.data.shop];
			shop = shop.filter(l => l.invoiceNumber !== action.data.invoice);
			invoices[action.data.shop] = shop;
			return {
				...state,
				invoices,
				loading: false
			};
		}
		case LOGOUT: {
			return defaultState;
		}
	}
	return state;
}
