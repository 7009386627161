import React, {useState} from "react";
import NTMModal from "./NTMModal";
import italia from "italia";

let classNames = require('classnames');

function getRegioni() {
	return italia.regioni.map(r => r.nome);
}

function getProvince(regione) {
	return italia.comuni.regioni.find(r => r.nome.toLowerCase() === regione.toLowerCase()).province.map(p => p.code);
}

function getComuni(regione, provincia) {
	return italia.comuni.regioni.find(r => r.nome.toLowerCase() === regione.toLowerCase()).province.find(p => p.code.toLowerCase() === provincia.toLowerCase()).comuni.map(c => c.nome);
}

export default function NTMFormModal({cols = 1, readonly, initialValues, formData, onClose, onSubmit, title}) {

	let formgroupClassnames = classNames("form-group", "form-row", "col-12", `col-md-${12 / cols}`);
	let inputgroupClassnames = classNames("input-group", "mb-2", "col");
	let fieldClassnames = classNames("form-control", "col", {"form-control-plaintext": readonly});

	let [values, setValues] = useState(initialValues || {});
	let [errors, setErrors] = useState({});
	const regions = getRegioni();
	let [provinces, setProvinces] = useState();
	let [comuni, setComuni] = useState();

	const RegionField = ({name, props}) => {

		let options = ["-"];
		if (name === "region") {
			options = options.concat(regions);
		} else if (name === "province" && values.region) {
			if (!provinces) setProvinces(getProvince(values.region));
			options = options.concat(provinces);
		} else if (name === "city" && values.region && values.province) {
			if (!comuni) setComuni(getComuni(values.region, values.province));
			options = options.concat(comuni);
		}

		return <select
			className={fieldClassnames}
			value={values[name]}
			onChange={({target}) => {
				if (name === "region") setProvinces(getProvince(target.value));
				if (name === "province") setComuni(getComuni(values.region, target.value));
				onChange(name, target.value);
			}} {...props}>
			{options.map(opt => <option key={opt} value={opt}>
				{opt}
			</option>)}
		</select>;
	};

	function getField({name, props, options, type}) {
		if (options)
			return <select name={name}
			               className={fieldClassnames}
			               value={values[name]}
			               onChange={({target}) => onChange(name, target.value)}
			               {...props}>
				{options.map(opt => <option key={opt.value}
				                            value={opt.value}>
					{opt.name || opt.value}
				</option>)}
			</select>;
		if (type === "textarea")
			return <textarea name={name}
			                 className={fieldClassnames}
			                 value={values[name]}
			                 onChange={({target}) => onChange(name, target.value)}
			                 {...props}>
			</textarea>;
		if (type === "region") return <RegionField name={name} props={props}/>;

		if (type === "checkbox") return <input type={type} name={name}
		                                       className={fieldClassnames}
		                                       checked={values[name] || false}
		                                       onChange={({target}) => onChange(name, !values[name])}
		                                       {...props}
		/>;

		return <input type={type} name={name}
		              className={fieldClassnames}
		              value={values[name]}
		              onChange={({target}) => onChange(name, target.value)}
		              {...props}
		/>;
	}

	function onChange(name, value) {
		setError(name, null);
		setValues(values => {
			values[name] = value;
			console.log(values);
			return values;
		});
	}

	function setError(field, message) {
		let newErrors = Object.assign({}, errors);
		newErrors[field] = message;
		setErrors(newErrors);
	}

	function handleSubmit() {
		let err = false;
		let errors = {};
		formData.filter(d => d.props && d.props.required).forEach(d => {
			if (!values[d.name]) {
				errors[d.name] = `*Campo ${d.label} obbligatorio`;
				err = true;
			}
		});

		for (let key in values) {
			if (values.hasOwnProperty(key)) {
				let elem = formData.find(d => d.name === key);
				if (elem && elem.props && elem.props.validate) {
					const validate = elem.props.validate(values[key]);
					if (validate) {
						err = true;
						setError(key, validate);
					}
				}

				if (RegExp('^\\d+$').test(values[key])
					&& key !== "recipientCode"
					&& key !== "piva") {
					values[key] = Number.parseInt(values[key]);
				}
			}
		}
		if (err) setErrors(errors);
		else onSubmit(values);
	}

	return (
		<NTMModal
			onConfirm={handleSubmit}
			title={title}
			isOpen={true}
			onClose={onClose}
			style={{backgroundColor: "white"}}
		>
			<div style={{
				display: "flex",
				alignItems: "flex-start",
				justifyContent: "flex-start",
				flexFlow: "row wrap",
				alignContent: "flex-start",
				backgroundColor: "white"
			}}>
				{
					formData.map(data => {

						if (data.dependsOn && !values[data.dependsOn])
							return null;

						return (
							<React.Fragment key={data.name}>{errors[data.name] &&
							<div className={formgroupClassnames} style={{color: "red"}}>
								{errors[data.name]}
							</div>
							}
								<div className={formgroupClassnames}>
									<label htmlFor={data.name} className={"col"}
									       style={{fontWeight: "bold"}}>{data.label || data.name}</label>
									<div className={inputgroupClassnames}>
										{
											data.prepend && <div className="input-group-prepend">
												<div className="input-group-text">{data.prepend}</div>
											</div>
										}
										{getField(data)}
									</div>
								</div>


							</React.Fragment>);
					})
				}
			</div>
		</NTMModal>
	);

}
