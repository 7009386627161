import React, {Component} from 'react';
import classNames from 'classnames';
import "./_button.scss";

export default class NTMButton extends Component {

	render() {

		let type, className;
		let props = Object.assign({}, this.props);
		if (!props.style) props.style = {};

		if (props.primary) {
			className = classNames("primary");
		}
		if (props.secondary) {
			className = classNames("secondary");
		}
		if (props.red) {
			className = classNames("red");
		}

		if (props.outline) {
			className = classNames(className, "outline");
		}

		if (props.block) {
			className = classNames(className, "block");
		}

		if (props.circle) {
			className = classNames(className, "cirle");
		}

		if (props.color) {
			if (props.outline) {
				props.style.color = props.color;
				props.style.borderColor = props.color;
			} else {
				props.style.backgroundColor = props.color;
				props.style.color = "white";
			}
		}
		delete props.outline;
		delete props.circle;
		delete props.primary;
		delete props.secondary;
		delete props.red;

		return (
			<button {...props} className={className}>
				{this.props.children}
			</button>
		);
	}
}