import React from "react";
import {connect} from "react-redux";

import DownloadPage from "./DownloadPage";

import {list as getProducts} from "../../redux/products/productsActions";
import {list as getUsers} from "../../redux/users/userActions";
import {list as getCards} from "../../redux/cards/cardsActions";
import {list as getShops, getShopCategories} from "../../redux/shops/shopsActions";

const mapStateToProps = ({users, shops, cards, products}) => ({
	users: users.list,
	shops: shops.list,
	cards: cards.list,
	shop_categories: shops.categories,
	generics: products.generics,
	categories: products.categories,
});

const mapDispatchToProps = {
	getProducts, getUsers, getCards, getShops, getShopCategories
};

export default connect(mapStateToProps, mapDispatchToProps)(DownloadPage);
