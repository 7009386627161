import {firebaseDatabase, firebaseStorage} from '../../firebase';
import {
	addCard,
	editCard,
	loading,
	removeCard,
	error,
	success, CLEAR, updateStorageLink, saveOpenInvoices, saveClosedInvoices, listDiocesesCreator
} from "./cardsActionsCreator";
import {CARDS, Client, DIOCESE, DIOCESE_CLOSED_INVOICES, DIOCESE_INVOICES, INVOICES} from "../utils/Client";
import moment from "moment/moment";
import {updateUser} from "../users/userActions";

let client = new Client();

export function list() {
	return dispatch => {
		let ref = firebaseDatabase.ref("cards");
		ref.on('child_added', (snap) => {
			dispatch(addCard(snap.val()));
		});
		ref.on('child_removed', (snap) => {
			dispatch(removeCard(snap.val()));
		});
		ref.on('child_changed', (snap) => {
			dispatch(editCard(snap.val()));
		});
	};
}

export function setCardEnabled(card, enabled) {
	return dispatch => client.update({active:enabled}, `${CARDS}/${card.cardId}`).catch(e=>manageError(e, dispatch));
}

export function deleteCard(cardId) {
	return dispatch => client.delete({id:cardId},CARDS).catch(e=>manageError(e, dispatch));
}

export function listDioceses() {
	return dispatch => {
		client.get(DIOCESE)
			.then(response => {
				dispatch(listDiocesesCreator(response.data));
			}).catch(e => manageError(e, dispatch));

	};
}

export function saveDiocese(diocese) {
	return dispatch => {
		dispatch(loading());
		let promise = diocese.id ?
			client.update(diocese, DIOCESE) :
			client.save(diocese, DIOCESE);
		promise
			.then(response => {
				dispatch(listDioceses());
				dispatch(success());
			})
			.catch(e => manageError(e, dispatch));
	};
}

export function deleteDiocese(diocese, supervisors) {
	return dispatch => {
		dispatch(loading());
		client.delete(diocese, DIOCESE)
			.then(response => {
				if(supervisors) supervisors.forEach(s => {
					s.type=1;
					s.dioceseId=-1;
					s.dioceseName="";
					dispatch(updateUser(s));
				});
				dispatch(success());
				dispatch(listDioceses());
			})
			.catch(e => manageError(e, dispatch));
	};
}

export function getDioceseOpenInvoices(diocese) {
	return dispatch => {
		client.get(DIOCESE_INVOICES, diocese)
			.then(response => {
				dispatch(saveOpenInvoices(diocese, response.data));
			})
			.catch(e => manageError(e, dispatch));
	};
}

export function getDioceseClosedInvoices(diocese) {
	return dispatch => {
		client.get(DIOCESE_CLOSED_INVOICES, diocese)
			.then(response => {
				response.data.forEach(i => {
					i.invoiceDate = moment(i.invoiceDate).format("DD/MM/YYYY");
					if(i.link)
					firebaseStorage.refFromURL(i.link).getDownloadURL()
						.then(url => dispatch(updateStorageLink(diocese, i.invoiceNumber, "link", url)));
					if(i.eInvoiceLink)
					firebaseStorage.refFromURL(i.eInvoiceLink).getDownloadURL()
						.then(url => dispatch(updateStorageLink(diocese, i.invoiceNumber, "eInvoiceLink", url)));
				});
				dispatch(saveClosedInvoices(diocese, response.data));
			})
			.catch(e => manageError(e, dispatch));
	};
}

export function closeInvoice(diocese, invoice, number) {
	return dispatch => {
		dispatch(loading());
		client
			.save("", `${DIOCESE_INVOICES}/${invoice}/${number}`)
			.then(resp => {
				dispatch(getDioceseOpenInvoices(diocese));
				dispatch(getDioceseClosedInvoices(diocese));
				dispatch(success());
			})
			.catch(e => manageError(e, dispatch));
	};
}

function manageError(e, dispatch) {
	dispatch(loading(false));
	if (e.response && e.response.data.message) {
		// The request was made and the server responded with a status code
		// that falls out of the range of 2xx
		dispatch(error(e.response.data.message));
	} else {
		// Something happened in setting up the request that triggered an Error
		console.log('Error', e.message);
		dispatch(error(e.message));
	}
}

export function clear() {
	return {
		type: CLEAR
	};
}
