import React from 'react';
import {connect} from 'react-redux';
import {setUserEnabled, updateUser} from '../../redux/users/userActions';
import UsersPage from "./UsersPage";

function mapStateToProps({users, cards}) {
	return {
		users: users.list,
		cards: cards.list
	};
}

const mapDispatchToProps = {
	setUserEnabled,
	updateUser
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersPage);

