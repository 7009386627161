import React from "react";
import moment from "moment";
import {Button, Row} from "reactstrap";
import CSVExporter from "../../components/Table/CSVExporter";
import Loading from "react-loading";
import {DateRangePicker} from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import NTMButton from "../../components/Buttons";
import {Client} from "../../redux/utils";

let client = new Client();


export default class DashboardPage extends React.Component {

	state = {
		startDate: null,
		endDate: null
	};

	componentDidMount() {
		if (!this.props.categories) this.props.getProducts();
		if (!this.props.users) this.props.getUsers();
		if (!this.props.cards) this.props.getCards();
		if (!this.props.shops) this.props.getShops();
		if (!this.props.products) this.props.getShopCategories();
	}

	findTypeName = type => {
		switch (type) {
			case 1:
				return "Donatore";
			case 2:
				return "Partner";
			case 3:
				return "Supervisore";
			case 4:
				return "Beneficiario";
			case 5:
				return "Amministr. Diocesi";
			default:
				return "Superuser";
		}
	};

	usersToCsvData = data => data.map(u => {
		return {
			uid: u.uid,
			nome: u.firstName,
			cognome: u.lastName,
			email: u.email,
			"data di nascita": u.birthday,
			"paese": u.city,
			"luogo di nascita": u.cityOfBirth,
			"data di iscrizione": moment(u.createdAt).format("YYYY-MM-DD HH:mm"),
			"ultimo accesso": moment(u.lastAccess).format("YYYY-MM-DD HH:mm"),
			"Documento rilasciato da": u.docIssuer,
			"Numero documento": u.docNumber,
			"Tipo documento": u.docType,
			sesso: u.gender,
			"cittadinanza": u.nationality,
			telefono: u.phone,
			"tipologia": this.findTypeName(u.type)
		};
	});

	shopsToCsvData = data => data.filter(s => s.type !== 1001).map(s => {
		let cat = this.props.shop_categories.find(c => c.id === s.type);
		return {
			id: s.id,
			nome: s.name,
			"Denominazione Sociale": s.denSociale,
			indirizzo: s.address,
			cap: s.cap,
			paese: s.city,
			provincia: s.province,
			email: s.email,
			iban: s.iban,
			piva: s.piva,
			categoria: cat ? cat.name : "",
			prodotti: s.products ? s.products.length : 0
		};
	});

	productsToCsvData = data => {
		let csv = [];
		data.forEach(s => s.products && csv.push(...s.products.map(p => {
			let generic = this.props.generics.find(pg => pg.id === p.generic_product);
			let category = this.props.categories.find(c => c.id === generic.product_category);

			return {
				id: p.id,
				nome: p.productName,
				descrizione: p.description,
				negozio: s.name,
				"città": s.city,
				"Ente Supervisore": s.fundName || "",
				cat_negozio: s.categoria,
				"Prezzo Venditore": parseFloat(p.priceToVendor.toFixed(2)).toLocaleString('it-IT', {
					currency: "EUR",
					useGrouping: false
				}),
				"Prezzo Pubblico": parseFloat(p.priceToClient.toFixed(2)).toLocaleString('it-IT', {
					currency: "EUR",
					useGrouping: false
				}),
				"Prodotto generico": generic.name,
				iva: generic.iva,
				tucum: generic.tucum,
				categoria: category.name,
				"Intervallo di erogazione": category.erogationInterval,
				"Quantità massima giornaliera": category.dailyErogation
			};
		})));
		return csv;
	};

	updateURL = () => {
		let params = {
			millis: this.state.endDate.valueOf(),
			day: this.state.endDate.diff(this.state.startDate, 'days') + 1
		};
		this.setState({loading: true});
		client.download("transactions/csv", params).then((response) => {
			this.setState({loading: false});
			const blob = new Blob([response.data], {type: response.data.type});
			const url = window.URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.href = url;
			const contentDisposition = response.headers['content-disposition'];
			let fileName = 'unknown';
			if (contentDisposition) {
				const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
				if (fileNameMatch.length === 2)
					fileName = fileNameMatch[1];
			}
			link.setAttribute('download', fileName);
			document.body.appendChild(link);
			link.click();
			link.remove();
			window.URL.revokeObjectURL(url);
		})

			.catch(e =>
				this.setState({loading: false}));
	};

	render() {
		return (
			<div>
				<Row>
					{this.props.users && this.props.users.length > 0 ?
						<CSVExporter name={"utenti"} data={this.usersToCsvData(this.props.users)}/> :
						<Loading/>
					}
					{this.props.shops && this.props.shops.length > 0 && this.props.shop_categories.length > 0 ?
						<CSVExporter name={"negozi"} data={this.shopsToCsvData(this.props.shops)}/> :
						<Loading/>
					}
					{this.props.shops && this.props.shops.length > 0 && this.props.shop_categories.length > 0 ?
						<CSVExporter name={"prodotti"} data={this.productsToCsvData(this.props.shops)}/> :
						<Loading/>
					}
				</Row>
				<Row style={{marginTop: "1.5rem"}}>
					<div style={styles.ctr}>{"Esporta donazioni nell'intervallo:"}</div>
					<DateRangePicker
						style={styles.ctr}
						startDate={this.state.startDate} // momentPropTypes.momentObj or null,
						startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
						endDate={this.state.endDate} // momentPropTypes.momentObj or null,
						endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
						onDatesChange={({startDate, endDate}) => {
							this.setState({startDate, endDate});
						}} // PropTypes.func.isRequired,
						focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
						onFocusChange={focusedInput => this.setState({focusedInput})} // PropTypes.func.isRequired
						minimumNights={0}

						startDatePlaceholderText={"Inizio"}
						endDatePlaceholderText={"Fine"}
						displayFormat={"DD/MM/YYYY"}
						isOutsideRange={(o) => o.isAfter(moment(), 'day')}
						small={true}
					/>
					<NTMButton style={styles.ctr}
					           disabled={this.state.startDate == null || this.state.endDate === null || this.state.loading === true}
					           primary onClick={this.updateURL}>
						{this.state.loading === true ? "Genero..." : "Scarica"}
					</NTMButton>
				</Row>
			</div>
		);
	}
}

const styles = {
	ctr: {
		marginTop: "auto",
		marginBottom: "auto",
		marginLeft: "1rem"
	}
};
