import React, {Component} from "react";

import './_modal.scss';
import NTMButton from "../Buttons/NTMButton";
import {Modal, ModalBody, Nav, Navbar, NavbarBrand, NavItem} from "reactstrap";

export default class NTMModal extends Component {

	render() {

		let {isOpen, title, onClose, onConfirm} = this.props;

		let nav = (
			<Navbar expand="md">
				<NavbarBrand>{title}</NavbarBrand>
				<Nav className="ml-auto" navbar>
					<NavItem>
						<NTMButton onClick={onConfirm} secondary>
							<i className="fa fa-check" aria-hidden="true"/>
						</NTMButton>
					</NavItem>
					<NavItem>
						<NTMButton onClick={onClose} secondary>
							<i className="fa fa-times" aria-hidden="true"/>
						</NTMButton>
					</NavItem>
				</Nav>
			</Navbar>
		);

		return (
			<Modal isOpen={isOpen} toggle={onClose}>
				<div className={"ntm-modal-header"}>
					{nav}
				</div>
				<ModalBody>
					{this.props.children}
				</ModalBody>
			</Modal>
		);
	}
}