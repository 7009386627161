import React, {Component} from "react";
import {
	Dropdown,
	DropdownMenu,
	DropdownItem,
	Nav, NavItem,
	NavbarToggler
} from "reactstrap";
import {Link, NavLink} from "react-router-dom";

import styles from "./header.module.scss";

class Header extends Component {

	constructor(props) {
		super(props);

		this.state = {
			dropdownOpen: false,
			venuesOpen: false,
			isOpen: false
		};
	}

	collapse = () => {
		this.setState({
			isOpen: !this.state.isOpen
		});
	};

	toggle = () => {
		this.setState({
			dropdownOpen: !this.state.dropdownOpen
		});
	};

	toggleVenues = () => {
		this.setState({
			venuesOpen: !this.state.venuesOpen
		});
	};

	sidebarToggle(e) {
		e.preventDefault();
		document.body.classList.toggle("sidebar-hidden");
	}

	sidebarMinimize(e) {
		e.preventDefault();
		document.body.classList.toggle("sidebar-minimized");
	}

	mobileSidebarToggle(e) {
		e.preventDefault();
		document.body.classList.toggle("sidebar-mobile-show");
	}

	asideToggle(e) {
		e.preventDefault();
		document.body.classList.toggle("aside-menu-hidden");
	}

	largeScreenMenu = (username, logout) => <Nav className={`ml-auto d-md-down-none ${styles.topmenu}`} navbar>

		<NavItem className={styles.navItem} onClick={logout}>
			<span className={`d-md-down-none ${styles.text}`}>{"Logout"}</span>
		</NavItem>
	</Nav>;

	mobileMenu = (username, logout) =>
		<Nav className={`ml-auto d-lg-none  ${styles.topmenu}`} navbar>
			<NavItem className={styles.navItem}>
				<Dropdown isOpen={this.state.isOpen} toggle={this.collapse}>
					<DropdownMenu right className={this.state.venuesOpen ? "show" : ""}>
						<DropdownItem
							id={"logout-button"}
							onClick={null}>
							<NavLink to={"profile"}>
								<span className={`${styles.text}`}>{username}</span>
							</NavLink>
						</DropdownItem>
						<DropdownItem
							id={"logout-button"}
							onClick={logout}>
							<span className={`${styles.text}`}>{"Logout"}</span>
						</DropdownItem>
					</DropdownMenu>
				</Dropdown>
			</NavItem>
		</Nav>;

	render() {

		let {logout, user} = this.props;
		let username = user ? `${user.firstName} ${user.lastName}` : "User Name";

		return (
			<header className="app-header navbar">
				<NavbarToggler className="d-md-down-none burger-button"
				               style={{position: "absolute"}}
				               onClick={this.sidebarMinimize}>&#9776;</NavbarToggler>
				<NavbarToggler className="d-lg-none menu-top-left"
				               onClick={this.mobileSidebarToggle}>&#9776;</NavbarToggler>
				<Link to={"/"}>
					<div className={styles.logo}>
						<img className={styles.navbarBrand} alt={"logo"} src={require("../../img/logo.png")}/>
					</div>
				</Link>

				{this.largeScreenMenu(username, logout)}

				{this.mobileMenu(username, logout)}

			</header>
		);
	}
}

export default Header;
