import {combineReducers} from "redux";
import users from "./users/userReducer";
import products from "./products/productReducer";
import settings from "./settings/settingsReducer";
import cards from "./cards/cardsReducer";
import shops from "./shops/shopsReducer";

const rootReducer = combineReducers({
	users, products, settings, cards, shops
});

export default rootReducer;
