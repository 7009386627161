import React, {useState} from "react";
import {Col, Row} from "reactstrap";
import NTMTable from "../components/Table/NTMTable";
import {useQuery, useQueryClient} from "react-query";
import {Client} from "../redux/utils";
import NTMLoadingModal from "../components/Modal/NTMLoadingModal";
import {MoneyCell} from "../components/Table/tableCells";
import moment from "moment";
import NTMButton from "../components/Buttons";
import SweetAlert from "react-bootstrap-sweetalert";
import {useSnackbar} from "notistack";

let client = new Client();

export default function RegistryPage() {

	let {
		data = [],
		isLoading
	} = useQuery("registry", () => client.get("transactions/registry").then(response => response.data));
	let queryClient = useQueryClient();
	let [modal, setModal] = useState();
	const {enqueueSnackbar} = useSnackbar();

	function manageError(e) {
		enqueueSnackbar(client.getErrorMessage(e), {variant: "error"});
	}

	let list = data.map(d => {
		return {
			...d,
			ben: d.externalFund || d.shop || d.diocese || "-",
			val: d.validated ? "OK" : "-"
		};
	});

	function closeModal() {
		setModal(null);
	}

	function validate(tid) {
		closeModal();
		client
			.get(`transactions/registry/${tid}/validate`)
			.then(() => enqueueSnackbar("Validata", {variant: "success"}))
			.catch(manageError)
			.finally(() => queryClient.invalidateQueries("registry"))
	}

	function setError(tid) {
		closeModal();
		client
			.update(`Invalidata manualmente il ${moment().format("DD-MM-YYYY")}`, `transactions/registry/${tid}`)
			.then(() => enqueueSnackbar("Invalidata", {variant: "success"}))
			.catch(manageError)
			.finally(() => queryClient.invalidateQueries("registry"))
	}

	function validateCell(registry) {
		if (registry.validated) return "OK";
		else if (registry.error) return "-";
		else return <div>
				<NTMButton
					primary
					onClick={() => setModal(
						<SweetAlert
							showCancel
							confirmBtnText={"Conferma"}
							cancelBtnText={"Annulla"}
							cancelBtnBsStyle={"default"}
							title={"Confermi?"}
							onConfirm={() => validate(registry.transactionId)}
							onCancel={closeModal}
						>
							{`Vuoi validare manualmente la transazione di ${(registry.total / 100).toFixed(2)}€ effettuata da ${registry.email}?`}
						</SweetAlert>
					)}
				>Valida</NTMButton>
				<NTMButton
					red
					onClick={() => setModal(
						<SweetAlert
							showCancel
							confirmBtnText={"Conferma"}
							confirmBtnBsStyle={"danger"}
							cancelBtnText={"Annulla"}
							cancelBtnBsStyle={"default"}
							title={"Confermi?"}
							onConfirm={() => setError(registry.transactionId)}
							onCancel={closeModal}
						>
							{`Vuoi invalidare manualmente la transazione di ${(registry.total / 100).toFixed(2)}€ effettuata da ${registry.email}? Verrà indicata come "Invalidata manualmente"`}
						</SweetAlert>
					)}
				>Invalida</NTMButton>
			</div>;

	}

	return <div className="animated fadeIn">
		{isLoading && <NTMLoadingModal/>}
		{modal}
		<Row>
			<Col>
				<NTMTable
					title={"Registro transazioni"}
					filterable={true}
					header={
						[
							{
								Header: 'ID',
								accessor: 'transactionId' // String-based value accessors!
							},
							{
								Header: 'Email',
								accessor: 'email' // String-based value accessors!
							},
							{
								Header: 'Nome',
								accessor: 'userFirstName' // String-based value accessors!
							},
							{
								Header: 'Cognome',
								accessor: 'userLastName',
							},
							{
								Header: 'Data',
								accessor: 'date',
								width: 180,
								sortMethod: (a, b) => moment(b, "DD/MM/YYYY HH:mm") - moment(a, "DD/MM/YYYY HH:mm")
							},
							{
								Header: 'Validata',
								accessor: 'val',
								Cell: props => validateCell(props.original)
							},
							{
								Header: 'Dispositivo',
								accessor: 'device',
							},
							{
								Header: 'Tot',
								accessor: 'total',
								Cell: props => <MoneyCell value={props.original.total / 100}/>
							},
							{
								Header: 'Tipo',
								accessor: 'type',
							},
							{
								Header: 'Errore',
								accessor: 'error',
								width: 200,
							},
							{
								Header: 'Beneficiario',
								accessor: "ben",
								width: 300,
							}]}
					defaultPageSize={20}
					body={list}
				/>
			</Col>
		</Row>
	</div>;

}

/*
customButtons={
							<React.Fragment>
								<div style={{display: "flex"}}>
									<div style={{display: "flex", flexGrow: 1}}>
										<NTMButton onClick={closeModal}>Annulla</NTMButton>
									</div>
									<div style={{display: "flex", flexGrow: 2}}>
										<NTMButton red
										           onClick={() => setError(registry.transactionId)}>Invalida</NTMButton>
										<NTMButton primary
										           onClick={() => validate(registry.transactionId)}>Conferma</NTMButton>
									</div>
								</div>
							</React.Fragment>
						}
 */
