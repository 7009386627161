//Lista delle voci del menu
//Aggiungi un elemento nell'array per avere una nuova voce
// Per le icone puoi scegliere tra fontawesome e simple line icons

export default {
	itemsUser: [
		{
			name: "Dashboard",
			url: "/dashboard",
			icon: "icon-speedometer"
		},
		{
			name: "Negozi",
			url: "/shops",
			icon: "fa fa-shopping-cart"
		},
		{
			name: "Prodotti",
			url: "/products",
			icon: "fa fa-shopping-basket"
		},
		{
			name: "Fondi esterni",
			url: "/externals",
			icon: "fa fa-university"
		},
		{
			name: "Enti Fondi",
			url: "/hanging",
			icon: "fa fa-credit-card"
		},
		{
			name: "Fondi Sospesi",
			url: "/bank-accounts",
			icon: "fa fa-money"
		},
		{
			name: "Utenti",
			url: "/users",
			icon: "fa fa-users"
		},
		{
			name: "Carte",
			url: "/cards",
			icon: "fa fa-id-card-o"
		},
		{
			name: "Impostazioni",
			url: "/settings",
			icon: "fa fa-cog"
		},
		{
			name: "Download",
			url: "/downloader",
			icon: "fa fa-download"
		},
		{
			name: "Amministrazione",
			url: "/appacutis",
			icon: "fa fa-money"
		},
		{
			name: "Controllo Nexi",
			url: "/registry",
			icon: "fa fa-credit-card"
		}
	]
};

