import moment from "moment";
import React from "react";

export function MoneyCell({value}) {
	return <div>{value ? value.toLocaleString("it-IT", {
		style: "currency",
		currency: "EUR",
		minimumFractionDigits: 2
	}) : "-"}</div>;
}

export function TimestampCell({value}) {
	return <div>{value ? moment(value).format("DD/MM/YYYY HH:mm") : "-"}</div>;
}

export function UserCell({user, onClick}) {
	if (!user) return <div>{"Sconosciuto"}</div>;
	let username = `${user.firstName} ${user.lastName}`;

	return (
		<div
			className={"userCell"}
			onClick={onClick}>
			{username}
		</div>);
}

