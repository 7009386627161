import React, {useEffect} from "react";
import {Switch, Route, Redirect, useLocation} from "react-router-dom";
import {Container} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";

//Components
import Header from "../Header/Header";
import Sidebar from "../../components/Sidebar/";
import Footer from "@nextome/nxtfooter";

//Actions
import {login, logout} from "../../redux/users/userActions";

//Pages
import DashboardPage from "../DashboardPage";
import SettingsPage from "../Settings/SettingsPage";
import ProductContainer from "../Products/ProductsContainer";
import ProfileContainer from "../Profile/ProfileContainer";
import AdminPageContainer from "../Users/UsersContainer";
import ShopsPage from "../Shops/ShopsPage";
import ShopProfileContainer from "../Profile/ShopProfileContainer";
import DioceseProfileContainer from "../Profile/DioceseProfileContainer";
import DownloadContainer from "../Download/DownloadContainer";
import ExternalFundsContainer from "../ExternalFunds/ExternalFundsContainer";
import CardPage from "../Cards/CardPage";
import BankAccounts from "../BankAccounts";
import AdminPage from "../AdminPage";
import RegistryPage from "../RegistryPage";
import HangingFundPage from "../HangingFundPage";
import ShopPage from "../ShopPage";

export default function Full() {

	let users = useSelector(store => store.users);
	let dispatch = useDispatch();
	let location = useLocation();

	useEffect(() => dispatch(login()), []);

	if (users.ready && users.isLogged) {
		return (
			<div className="app">
				<Header logout={() => dispatch(logout())} user={users.data}/>
				<div className="app-body">
					<Sidebar location={location} userIsAdmin={false}/>
					<main className="main">
						<Container fluid>
							<Switch>
								<Route
									path="/dashboard"
									component={DashboardPage}
								/>
								<Route
									exact
									path="/shops"
									name="Negozi"
									component={ShopsPage}
								/>
								<Route
									path="/shops/:shopid"
								>
									<ShopPage/>
								</Route>
								<Route
									path="/externals"
									name="Negozio"
									component={ExternalFundsContainer}
								/>
								<Route
									path="/dioceses/:dioceseid"
									name="Diocesi"
									component={DioceseProfileContainer}
								/>
								<Route
									path="/products"
									name="Prodotti"
									component={ProductContainer}
								/>
								<Route
									path="/users"
									name="Utenti"
									component={AdminPageContainer}
								/>
								<Route path="/cards">
									<CardPage type={"caritas"}/>
								</Route>
								<Route path="/hanging">
									<CardPage type={"hanging"}/>
								</Route>>
								<Route path="/hangings/:hanging">
									<HangingFundPage/>
								</Route>
								<Route path="/bank-accounts">
									<BankAccounts/>
								</Route>
								<Route
									path="/settings"
									component={SettingsPage}
								/>
								<Route
									path="/downloader"
									name="Downloader"
									component={DownloadContainer}
								/>
								<Route
									path="/profile"
									name="Profile"
									component={ProfileContainer}
								/>
								<Route path="/appacutis">
									<AdminPage/>
								</Route>
								<Route path="/registry">
									<RegistryPage/>
								</Route>
								<Redirect exact from={"/dioceses"} to={"/cards"}/>
								<Redirect exact from="/" to="/dashboard"/>
								<Redirect from="/" to="/404"/>
							</Switch>
						</Container>
					</main>
				</div>
				<Footer copyright={"A.P.P. Acutis srls"} copyrightLink={"http://tucum.appacutis.it"}/>
			</div>
		);
	} else if (users.ready && users.isLogged === false) {
		return <Redirect to="/login"/>;
	} else {
		return <div/>;
	}

}
