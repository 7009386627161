import React from 'react';
import NTMButton from '../../components/Buttons/NTMButton';

export class LoginButton extends React.Component {

	render() {
		let text = <div>Log In</div>;
		let classes = this.props.className;
		let disabled = false;
		if (this.props.isLoading) {
			classes += ' disabled';
			text = <div><i className='fa fa-spinner fa-spin'/> Logging</div>;
			disabled = true;
		}
		return (
			<NTMButton
				type="submit"
				disabled={disabled}
				className={classes}
				onClick={this.props.onClick}
				primary={this.props.primary}
			>
				{text}
			</NTMButton>);
	}
}