import React from 'react';
import {InputGroup, InputGroupAddon, Input, Tooltip, Popover, PopoverHeader, PopoverBody} from 'reactstrap';

/*
* React class in which the input of the registration page is implemented
* */
export default class ModalRegistrationInput extends React.Component {

  constructor(props){
    super(props);
    this.inputChangeListener = this.inputChangeListener.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.togglePopover = this.togglePopover.bind(this);
    this.validate = this.validate.bind(this);
    this.state = {
      value : this.props.value,
      tooltipOpen: false,
      popoverOpen: false,
      popoverHeader:"Input info",
      differentPassword: false,
      popoverMessage:{
        firstName: "The FIRST NAME field can not be empty, can not be longer than 30 characters and can only contain letters!",
        lastName: "The LAST NAME field can not be empty, can not be longer than 30 characters and can only contain letters!",
        username: "The USERNAME field can not be empty, it must be between 5 and 20 characters long and can contain only the following special characters . - _ ",
        email: "The EMAIL field can not be empty and must be in the form username@domain!",
        password: "The PASSWORD field must be an alphanumeric string of min 6 and max 16 characters, must contain at least one number and a special character(!@#$%^&*).",
        confirm: "Attention, passwords must be the same!"
      }
    }
  }
  /*
  * Toggle function for the input's popover
  * */
  togglePopover(){
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  }

	/*
	* Toggle function for the input's popover
	* */
	togglePopover() {
		this.setState({
			popoverOpen: !this.state.popoverOpen
		});
	}

	/*
	* Listener associated with the onChange event of each individual input field
	*
	* Input parameters:
	* 	e: type event, onChange event
	* */
	inputChangeListener(e) {
		this.setState({
			value: e.target.value
		}, () => {
			this.props.getValue(this.props.id, this.state.value, this.validate(this.state.value));
		});
	}

	/*
	  * Function that validates the input
	  *
	  *		value: value of the input field
	  *
	  *	Output:
	  *		Boolean value that indicates whether the value of the input field is valid
	  * */
	validate(value) {

		value = value.trim();
		let result = true;
		let id = this.props.id;
		let type = this.props.type;

    if(this.props.required && !value){
      result = false;
    }else if(value){
      if(type === "text"){
        if(id === "username" && !/^[a-zA-Z0-9\-._ ]{5,20}$/.test(value)){
          result = false;
        }else if((id === "firstName" || id === "lastName") && !/^[a-zA-Z ]{4,30}$/.test(value)){
          result = false;
        }
      }else if(type === "email" && !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})$/.test(value)){
        result = false;
      }else if(type === "password") {
        if(id === "password" && !/^(?=.*[\d])(?=.*[!@#$%^&*])[\w!@#$%^&*]{6,16}$/.test(value)) {
          result = false;
        }else if (id === "confirm" && (!/^(?=.*[\d])(?=.*[!@#$%^&*])[\w!@#$%^&*]{6,16}$/.test(value) || this.props.password !== value)){
          result = false;
          this.setState({
            differentPassword: true
          });
        }
      }
    }

		return result;
	}

	/*
	* Toggle function for the tooltip
	* */
	toggleTooltip() {
		this.setState({
			tooltipOpen: !this.state.tooltipOpen
		});
	}

	/*
	* Rendering function
	* */
	render() {
		return (
			<InputGroup className={"modal-input-div"}>
				<InputGroupAddon id={"addonType-" + this.props.id} addontype={this.props.addon}>
					<i className={this.props.icon} aria-hidden="true"/>
				</InputGroupAddon>
				<Input
					type={this.props.type}
					name={this.props.name}
					id={this.props.id}
					placeholder={this.props.required ? this.props.placeholder + " *" : this.props.placeholder}
					required={this.props.required}
					value={this.state.value}
					onChange={(e) => this.inputChangeListener(e)}
					onSubmit={(e) => {
						e.preventDefault();
					}}
					className={this.props.className}
					onFocus={this.togglePopover}
					onBlur={this.togglePopover}
				/>
				<Tooltip placement={"top"} isOpen={this.state.tooltipOpen} target={"addonType-" + this.props.id}
				         toggle={this.toggleTooltip} delay={{show: 0, hide: 100}}>
					{this.props.required ? this.props.placeholder + " *" : this.props.placeholder}
				</Tooltip>
				<Popover className={"popover-profile-page"} placement={"top"} isOpen={this.state.popoverOpen}
				         target={this.props.id} onClick={this.togglePopover} container={".modal-body-signup-page"}>
					<PopoverHeader
						className={"popover-sign-up-page-header"}>{this.state.popoverHeader.toUpperCase()}</PopoverHeader>
					<PopoverBody
						className={"popover-profile-page-body"}>{this.props.id === "confirm" && this.state.differentPassword ? this.state.popoverMessage["confirm"] : this.state.popoverMessage[this.props.id]}</PopoverBody>
				</Popover>
			</InputGroup>
		);
	}
}