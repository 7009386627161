import React, {Component} from 'react';
import ReactTable from "react-table";
import 'react-table/react-table.css';
import './_table.scss';

export default class NTMTable extends Component {

	render() {

		let {header, body, defaultPageSize, filterable, title, onFilteredChange} = this.props;

		let columns = title ? [{Header: title, columns: header}] : header;
		return (
			<ReactTable
				data={body || []}
				columns={columns}
				defaultPageSize={defaultPageSize || 5}
				defaultFilterMethod={(filter, row) =>
					row[filter.id] && row[filter.id].toLowerCase().includes(filter.value && filter.value.toLowerCase())}
				filterable={filterable}
				onFilteredChange={onFilteredChange}
				previousText={"Precedente"}
				nextText={"Successiva"}
				noDataText={"Non ci sono dati"}
				pageText={"Pagina"}
				ofText={"di"}
				rowsText={"Elementi"}
				className={"-highlight"}
			/>
		);
	}
}
