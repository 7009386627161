import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Row, Col} from "reactstrap";
import NTMButton from "../components/Buttons";
import NTMTable from "../components/Table/NTMTable";
import {SuccessDialog, ConfirmDialog, ErrorDialog} from "@nextome/messages";
import EditUserModal from "./Users/EditUserModal";
import {Link} from "react-router-dom";
import {updateUser} from "../redux/users/userActions";
import {Client, USERS} from "../redux/utils/Client";

let client = new Client();

export default function DashboardPage() {

	let [alert, setAlert] = useState(null);
	let dioceses = useSelector(store => store.cards.dioceses);
	let shops = useSelector(store => store.shops.list);
	let users = useSelector(store => store.users.list);
	let dispatch = useDispatch();

	let closeAlert = () => setAlert(null);

	let getSuccessMessage = title => setAlert(<SuccessDialog onConfirm={closeAlert} title={title}/>);

	let getConfirmMessage = (title, message, buttonText, confirmMessage, onConfirm) => setAlert(
		<ConfirmDialog
			title={title}
			message={message}
			confirmBtnText={buttonText}
			onCancel={closeAlert}
			onConfirm={onConfirm}
		/>
	);

	let UserCell = user => {
		if (!user) return <div>{"Sconosciuto"}</div>;
		let username = `${user.firstName} ${user.lastName}`;

		return (
			<div
				className={"userCell"}
				onClick={() => {
					setAlert(
						<EditUserModal
							user={user}
							onSubmit={(values) => {
								dispatch(updateUser(values));
								closeAlert();
							}}
							onClose={closeAlert}
						/>);
				}}>
				{username}
			</div>);
	};

	let DioceseCell = dioceseId => {
		let diocese = dioceses.find(d => d.id === dioceseId);
		if (!diocese) return <div/>;
		return <Link
			to={{
				pathname: `/shops/${dioceseId}`,
				state: {fromDashboard: true}
			}}
		>
			{diocese.name}
		</Link>;
	};

	let ShopCell = shopid => {
		let shop = shops.find(s => s.id === shopid);
		if (!shop) return <div/>;
		return <Link
			to={{
				pathname: `/shops/${shopid}`,
				state: {fromDashboard: true}
			}}
		>
			{shop.name}
		</Link>;
	};

	let manageError = e => {
		let error;
		if (e.response) {
			// The request was made and the server responded with a status code
			// that falls out of the range of 2xx
			error = e.response.data.message;
		} else {
			// Something happened in setting up the request that triggered an Error
			error = e.message;
		}
		setAlert(
			<ErrorDialog title={"Errore"} message={error} onClose={closeAlert}/>
		);
	};

	let enablePartner = uid => client.get(`${USERS}/${uid}/accept`)
		.then(() => getSuccessMessage("Partner Abilitato"))
		.catch(manageError);

	let rejectPartner = uid => client.update({pendingPartner: false}, `${USERS}/${uid}`)
		.then(() => getSuccessMessage("Partner non abilitato"))
		.catch(manageError);

	let enableSupervisor = uid => client.get(`${USERS}/${uid}/accept`)
		.then(() => getSuccessMessage("Supervisore Abilitato"))
		.catch(manageError);

	let rejectSupervisor = uid => client.update({pendingSupervisor: false}, `${USERS}/${uid}`)
		.then(() => getSuccessMessage("Supervisore non Abilitato"))
		.catch(manageError);

	return (
		<div className="animated fadeIn">
			{alert}
			<Row>
				<Col>
					<NTMTable
						title={"Supervisori in attesa di approvazione"}
						header={
							[{
								Header: 'Nome',
								accessor: 'firstName',
								Cell: props => UserCell(props.original)
							}, {
								Header: 'Diocesi/Ente',
								Cell: props => DioceseCell(props.original.dioceseId)
							}, {
								Header: 'Email',
								accessor: 'email'
							}, {
								Header: 'Città',
								accessor: 'citta'
							}, {
								Header: 'Data di nascita',
								accessor: 'birthday'
							}, {
								Header: "Approva",
								accessor: 'approva',
								Cell: props => <div>
									<NTMButton
										primary outline
										onClick={() =>
											getConfirmMessage("Abilitare supervisore?",
												"Una volta abilitato avrà tutti i poteri di un supervisore",
												"Abilita",
												"Utente abilitato come supervisore",
												() => enableSupervisor(props.original.uid))
										}
									>
										<i className={"fa fa-check-circle-o"}/>
									</NTMButton>
									<NTMButton red outline
									           onClick={() =>
										           getConfirmMessage("Rifiutare il supervisore?",
											           "Se rifiuti di abilitare il supervisore, tornerà ad essere un utente standard",
											           "Rifiuta",
											           "Utente non abilitato come supervisore",
											           () => rejectSupervisor(props.original.uid))
									           }>
										<i className={"fa fa-times-circle"}/>
									</NTMButton>
								</div>
							}]}
						body={users.filter(u => u.pendingSupervisor)}
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					<NTMTable
						title={"Partner in attesa di approvazione"}
						header={
							[{
								Header: 'Nome',
								accessor: 'firstName',
								Cell: props => UserCell(props.original)
							}, {
								Header: 'Negozio',
								Cell: props => ShopCell(props.original.partner_venue)
							}, {
								Header: 'Email',
								accessor: 'email'
							}, {
								Header: 'Città',
								accessor: 'citta'
							}, {
								Header: 'Data di nascita',
								accessor: 'birthday'
							}, {
								Header: "Approva",
								accessor: 'approva',
								Cell: props => <div>
									<NTMButton primary outline
									           onClick={() =>
										           getConfirmMessage("Abilitare partner?",
											           "Una volta abilitato avrà tutti i poteri di un partner",
											           "Abilita",
											           "Utente abilitato come partner",
											           () => enablePartner(props.original.uid, props.original.partner_venue))
									           }>
										<i className={"fa fa-check-circle-o"}/>
									</NTMButton>
									<NTMButton red outline
									           onClick={() =>
										           getConfirmMessage("Rifiutare partner?",
											           "Se rifiuti di abilitare il partner, tornerà ad essere un utente standard",
											           "Rifiuta",
											           "Utente non abilitato come partner",
											           () => rejectPartner(props.original.uid))
									           }
									>
										<i className={"fa fa-times-circle"}/>
									</NTMButton>
								</div>
							}]}
						body={users.filter(u => u.pendingPartner)}
					/>
				</Col>
			</Row>
		</div>
	);
}

