import React from "react";
import ReactDOM from "react-dom";
import {Provider} from "react-redux";
import {applyMiddleware, createStore} from "redux";
import thunk from "redux-thunk";
import promiseMiddleware from "redux-promise-middleware";
import {BrowserRouter, Route, Switch} from "react-router-dom";

import reducers from "./redux";
// Styles
// Import Font Awesome Icons Set
import "font-awesome/css/font-awesome.min.css";
// Import Simple Line Icons Set
import "simple-line-icons/css/simple-line-icons.css";
// Import Main styles for this application
import "./scss/style.scss";
// Containers
import Full from "./containers/Full/";
import LoginContainer from "./containers/Login/LoginContainer";
// Views
import Register from "./pages/Register";
import Page404 from "./pages/Page404";
import Page500 from "./pages/Page500";
import {createLogger} from "redux-logger";
import {SnackbarProvider} from "notistack";
import {Button} from "reactstrap";
import {QueryClient, QueryClientProvider} from "react-query";
import NTMButton from "./components/Buttons";

let middleware = [promiseMiddleware(), thunk];


if (!process.env.NODE_ENV || process.env.NODE_ENV === "test") {
	// Dev-only middleware
	middleware = [
		...middleware,
		createLogger() // Logs state changes to the dev console
	];
}

const createStoreWithMiddleware = applyMiddleware(...middleware)(createStore);

export class App extends React.Component {
	render() {
		return (
			<BrowserRouter>
				<Switch>
					<Route
						exact
						path="/login"
						name="Login Page"
						component={LoginContainer}
					/>
					<Route
						exact
						path="/register"
						name="Register Page"
						component={Register}
					/>
					<Route exact path="/404" name="Page 404" component={Page404}/>
					<Route exact path="/500" name="Page 500" component={Page500}/>
					<Route path="/">
						<Full/>
					</Route>
				</Switch>
			</BrowserRouter>
		);
	}
}

const notistackRef = React.createRef();
const onClickDismiss = key => () => {
	notistackRef.current.closeSnackbar(key);
};

const queryClient = new QueryClient();

ReactDOM.render(
	<React.StrictMode>
		<Provider store={createStoreWithMiddleware(reducers)}>
			<SnackbarProvider
				ref={notistackRef}
				action={(key) => (
					<NTMButton red onClick={onClickDismiss(key)}>
						CHIUDI
					</NTMButton>
				)}
				autoHideDuration={3000}>
				<QueryClientProvider client={queryClient}>
					<App/>
				</QueryClientProvider>
			</SnackbarProvider>
		</Provider>
	</React.StrictMode>,
	document.getElementById("root")
);
