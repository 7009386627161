import React from 'react';
import {connect} from 'react-redux';
import ExternalFundsPage from "./ExternalFundsPage";
import {
	save, deleteShop, clear
} from "../../redux/shops/shopsActions";

function mapStateToProps({shops, users}) {
	return {
		shops: shops.list,
		categories: shops.categories,
		loading: shops.loading,
		success: shops.success,
		users: users.list
	};
}

const mapDispatchToProps = {
	save, deleteShop, clear
};

export default connect(mapStateToProps, mapDispatchToProps)(ExternalFundsPage);

