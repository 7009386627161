import React, {useEffect, useState} from "react";
import {Col, Row} from "reactstrap";
import NTMTable from "../../components/Table/NTMTable";
import NTMButton from "../../components/Buttons/NTMButton";
import NTMFormModal from "../../components/Modal/NTMFormModal";
import NTMLoadingModal from "../../components/Modal/NTMLoadingModal";
import EditUserModal from "../Users/EditUserModal";
import {SuccessDialog, ErrorDialog, ConfirmDialog} from "@nextome/messages";
import {
	setCardEnabled,
	deleteCard as deleteCardAction,
	saveDiocese,
	deleteDiocese as deleteDioceseAction,
	clear
} from "../../redux/cards/cardsActions";
import {updateUser} from "../../redux/users/userActions";

import "./_cards.scss";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import CSVExporter from "../../components/Table/CSVExporter";


export default function CardPage({type}) {

	let [modal, setModal] = useState(null);
	let users = useSelector(store => store.users.list);
	let {list: cards, loading, error, success} = useSelector(store => store.cards);
	let dioceses = useSelector(store => store.cards.dioceses);
	let dispatch = useDispatch();

	useEffect(() => setModal(loading ? <NTMLoadingModal/> : null), [loading]);
	useEffect(() => setModal(error ? <ErrorDialog
		message={error}
		onClose={() => {
			dispatch(clear());
			closeModal();
		}}
	/> : null), [error]);
	useEffect(() => setModal(success ? <SuccessDialog
		title={"Operazione eseguita"}
		onClose={() => {
			dispatch(clear());
			closeModal();
		}}
	/> : null), [success]);

	function closeModal() {
		setModal(null);
	}

	function editDiocese(diocese) {
		let formData = [
			{
				type: "text",
				name: "name",
				label: "Nome*",
				props: {
					required: true
				}
			}, {
				type: "text",
				label: 'Ragione Sociale*',
				name: 'fiscalName',
				props: {
					required: true
				}
			}, {
				type: "text",
				label: 'Partita IVA*',
				name: 'piva',
				props: {
					required: true
				}
			}, {
				type: "text",
				label: 'Codice Fiscale*',
				name: 'fiscalCode'
			}, {
				type: "email",
				label: 'PEC*',
				name: 'pec',
				props: {
					required: true
				}
			}, {
				type: "text",
				label: 'Codice Fatturaz. Elettr.',
				name: 'recipientCode',
				props: {validate: value => value && value.length !== 7 && "Attenzione! Il codice Fatturazione deve essere composto da 7 caratteri"}
			}, {
				type: "region",
				label: 'Regione*',
				name: 'region',
				props: {
					required: true
				}
			}, {
				type: "region",
				label: 'Provincia (Sigla)*',
				name: 'province',
				props: {
					required: true
				}
			}, {
				type: "region",
				label: 'Città*',
				name: 'city',
				props: {
					required: true
				}
			}, {
				type: "text",
				label: 'CAP*',
				name: 'cap',
				props: {
					required: true
				}
			}, {
				type: "text",
				label: 'Indirizzo*',
				name: 'address'
			}, {
				type: "text",
				label: 'Email di contatto',
				name: 'email'
			}, {
				type: "text",
				label: 'Numero di telefono',
				name: 'tel'
			},
		];
		if (type === "hanging") {
			formData.push({
				type: "text",
				name: "website",
				label: "Sito web"
			})
			formData.push({
				type: "checkbox",
				label: "Nascondi dall'app",
				name: "hide",

			})
		}
		setModal(
			<NTMFormModal
				initialValues={diocese}
				formData={formData}
				onSubmit={(values) => {
					values.type = type;
					values.nation = "IT";
					values.province = values.province && values.province.toUpperCase();
					Object.keys(values).forEach(k => {
						if (values[k] === "") values[k] = null;
					});
					dispatch(saveDiocese(values));
				}}
				onClose={closeModal}
				title={diocese ? "Modifica " + diocese.name : "Crea"}
			/>
		);
	}

	function deleteCard(card) {
		let user = users.find(u => card.beneficiaryId === u.uid);
		let username = user ? `${user.firstName} ${user.lastName}` : "Sconosciuto";
		setModal(
			<ConfirmDialog
				message={`Vuoi eliminare la carta associata a ${username}?`}
				onCancel={closeModal}
				onConfirm={() => {
					dispatch(deleteCardAction(card.cardId));
					closeModal();
				}}
			/>
		);
	}

	function cardsToCsv() {
		return cards.filter(c => c.type === type).map(c => {
			let user = users.find(u => c.beneficiaryId === u.uid);
			let username = "Sconosciuto";
			if (user) username = `${user.firstName} ${user.lastName}`;
			let supervisor = users.find(u => c.erogatedBy === u.uid);
			let erogated = "Sconosciuto";
			if (supervisor) erogated = `${supervisor.firstName} ${supervisor.lastName}`;
			return {
				'Card ID': c.cardId,
				Barcode: c.cardCode,
				Beneficiario: username,
				Email: user ? user.email : "",
				'Tucum disponibili': c.availableTucum.toFixed(2),
				'Supervisore Erogatore': erogated,
				'Ente': c.dioceseName,
				Abilitato: c.active
			};
		});
	}

	function deleteDiocese(diocese) {

		if (cards.find(c => c.dioceseId === diocese.id)) {
			setModal(<ErrorDialog
				message={"Impossibile eliminare, ci sono ancora delle carte associate"}
				onClose={closeModal}
			/>);
			return;
		}

		let supervisors = users.filter(u => (u.type === 3 || u.type === 5) && u.dioceseId === diocese.id);

		let message = `Vuoi eliminare la diocesi ${diocese.name}?`;
		if (supervisors && supervisors.length > 0) {
			message += ` Ci sono ${supervisors.length} supervisori che da ora in poi opereranno come donatori`;
		} else message += ` Non ci sono supervisori associati`;
		setModal(
			<ConfirmDialog
				message={message}
				onCancel={closeModal}
				onConfirm={() => dispatch(deleteDioceseAction(diocese, supervisors))}
			/>
		);
	}

	function getSuccessMessage(title) {
		setModal(<SuccessDialog onConfirm={closeModal} title={title}/>);
	}

	function getConfirmMessage(title, message, buttonText, confirmMessage, onConfirm) {
		setModal(
			<ConfirmDialog
				title={title}
				message={message}
				confirmBtnText={buttonText}
				onCancel={closeModal}
				onConfirm={() => {
					onConfirm();
					getSuccessMessage(confirmMessage);
				}}
			/>
		);
	}

	function UserCell(uid) {
		let user = users.find(u => uid === u.uid);

		if (!user) return <div>{"Sconosciuto"}</div>;
		let username = `${user.firstName} ${user.lastName}`;
		return (
			<div
				className={"userCell"}
				onClick={() => {
					setModal(
						<EditUserModal
							user={user}
							onSubmit={(values) => {
								dispatch(updateUser(values));
								closeModal();
							}}
							onClose={closeModal}
						/>);
				}}>
				{username}
			</div>);
	}

	let tableTitle = type === "caritas" ? "Diocesi" : "Enti fondi";
	let modalTitle = type === "caritas" ? "Crea nuova Diocesi" : "Crea nuovo Ente fondi";
	let baseUrl = type === "caritas" ? "dioceses" : "hangings";
	return (
		<div className="animated fadeIn">
			{modal}
			<Row>
				<NTMButton primary onClick={() => editDiocese()}>{modalTitle}</NTMButton>
			</Row>
			<Row>
				<Col>
					<NTMTable
						title={tableTitle}
						header={
							[{
								Header: 'Nome',
								accessor: 'name' // String-based value accessors!
							}, {
								Header: 'Città',
								accessor: 'city',
							}, {
								Header: 'Email di contatto',
								accessor: 'email'
							}, {
								Header: 'PEC',
								accessor: 'pec'
							}, {
								Header: 'Numero di telefono',
								accessor: 'tel'
							}, {
								Header: 'Cod. Fiscale',
								accessor: 'fiscalCode'
							}, {
								Header: 'Codice FE',
								accessor: 'recipientCode'
							}, {
								accessor: 'details',
								Cell: props => <div>
									<NTMButton primary outline
									           onClick={() => editDiocese(props.original)}
									>
										<i className={"fa fa-pencil-square"}/>
									</NTMButton>
									<NTMButton red
									           onClick={() => deleteDiocese(props.original)}
									>
										<i className={"fa fa-trash"}/>
									</NTMButton>
									<Link
										to={{
											pathname: `/${baseUrl}/${props.original.id}`,
											state: {fromDashboard: true}
										}}
									>
										<NTMButton primary>
											<i className="fa fa-external-link" aria-hidden="true"/>
										</NTMButton>

									</Link>
								</div>
							}]}
						body={dioceses.filter(d => d.type === type)}
					/>
				</Col>
			</Row>
			<Row><CSVExporter name={"carte"} data={cardsToCsv()}/></Row>
			<Row>
				<Col>
					<NTMTable
						title={"Carte"}
						header={
							[
								{
									Header: 'Card ID',
									accessor: 'cardId' // String-based value accessors!
								},
								{
									Header: 'Barcode',
									accessor: 'cardCode' // String-based value accessors!
								},
								{
									Header: "Beneficiario",
									accessor: 'beneficiaryId',
									Cell: props => UserCell(props.original.beneficiaryId)
								},
								{
									Header: "Tucum disponibili",
									accessor: 'availableTucum',
									Cell: props => <div>{props.original.availableTucum.toFixed(2)}</div>
								},
								{
									Header: 'Supervisore Erogatore',
									accessor: 'erogatedBy',
									Cell: props => UserCell(props.original.erogatedBy)
								},
								{
									Header: type === "caritas" ? "Diocesi" : "Ente fondi",
									accessor: 'dioceseName'
								},
								{
									Header: "Abilitato",
									accessor: 'active',
									filterable: false,
									Cell: props => <div>
										<i className={"fa fa-circle"}
										   style={{
											   cursor: "pointer",
											   color: props.original.active ? "green" : "red"
										   }}
										   onClick={() =>
											   getConfirmMessage(
												   !props.original.active ? "Abilitare carta?" : "Disabilitare carta?",
												   props.original.active ?
													   "Se disabiliti la carta, l'utente non potrà ottenere beni" :
													   "Se riattivi la carta, l'utente avrà accesso al sistema",
												   props.original.active ? "Disabilita" : "Abilita",
												   props.original.active ? "Carta Disabilitata" : "Carta abilitata",
												   () => dispatch(setCardEnabled(props.original, !props.original.active)))
										   }
										/>
									</div>
								},
								{
									filterable: false,
									Cell: props => <div>
										<NTMButton red
										           onClick={() => dispatch(deleteCard(props.original))}
										>
											<i className={"fa fa-trash"}/>
										</NTMButton>
									</div>
								},
							]
						}
						body={cards.filter(c => c.type === type)}
					/>
				</Col>
			</Row>

		</div>
	);
}
