import React from 'react';

import {Client, TokenManager} from '../../redux/utils/index';
import {Login} from './Login';

import {firebaseAuth} from "../../firebase";

import {Redirect} from 'react-router-dom';
import {connect} from "react-redux";

class LoginContainer extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			username: "",
			password: "",
			logged: false
		};
		this.client = new Client();
	}

	onError(error) {
		this.setState({
			loading: false,
			error: error
		});
	}

	onSubmit = (e) => {
		e.preventDefault();
		this.setState({loading: true});
		this.login();
	};

	usernameListener = (e) => {
		this.setState({username: e.target.value});
	};

	passwordListener = (e) => {
		this.setState({password: e.target.value});
	};

	login = () => {
		firebaseAuth.signInWithEmailAndPassword(this.state.username, this.state.password)
			.then(() => {
				this.setState({logged: true});
			})
			.catch((error) => {
				// Handle Errors here.
				let errorCode = error.code;
				let errorMessage = error.message;
				this.setState({
					loading: false,
					error: errorMessage
				});
			});
	};

	render() {
		if (this.state.logged || this.props.logged) {
			return <Redirect to={"/dashboard"}/>;
		} else {
			return <Login isLoading={this.state.loading}
			              error={this.state.error}
			              username={this.state.username}
			              onUsernameChange={this.usernameListener}
			              password={this.state.password}
			              onPasswordChange={this.passwordListener}
			              onSubmit={this.onSubmit}/>;
		}
	}
}

function mapStateToProps({users}) {

	return {
		ready: users.ready,
		logged: users.isLogged
	};
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);