import React, {Component} from "react";
import {Col, Row} from "reactstrap";
import NTMTable from "../../components/Table/NTMTable";
import {SuccessDialog, ErrorDialog, ConfirmDialog} from "@nextome/messages";
import NTMLoadingModal from "../../components/Modal/NTMLoadingModal";
import NTMButton from "../../components/Buttons";
import NTMFormModal from "../../components/Modal/NTMFormModal";


export default class ExternalFundsPage extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentWillReceiveProps(props) {
		if (props.loading) {
			this.openModal(<NTMLoadingModal/>);
		} else if (props.error) {
			this.openModal(<ErrorDialog
				message={props.error}
				onClose={() => {
					this.props.clear();
					this.closeModal();
				}}
			/>);
		} else if (props.success) {
			this.openModal(
				<SuccessDialog
					title={"Operazione eseguita"}
					onClose={() => {
						this.props.clear();
						this.closeModal();
					}}
				/>
			);
		}
	}

	closeModal = () => {
		this.setState({
			modal: null
		});
	};

	openModal = (modal) => {
		this.setState({
			modal
		});
	};

	delete = (shop) => {
		this.openModal(
			<ConfirmDialog
				message={`Vuoi eliminare il fondo esterno ${shop.name}?`}
				onCancel={this.closeModal}
				onConfirm={() => {
					this.props.deleteShop(shop);
					this.closeModal();
				}}
			/>
		);
	};

	edit = (shop) => {
		this.openModal(
			<NTMFormModal
				initialValues={shop}
				formData={[
					{
						type: "text",
						name: "name",
						label: "Intestazione ente"
					},
					{
						type: "text",
						name: "city",
						label: "Città"
					},
					{
						type: "text",
						name: "address",
						label: "Indirizzo"
					},
					{
						type: "text",
						name: "email",
						label: "Email di contatto"
					},
					{
						type: "text",
						name: "piva",
						label: "P. IVA / C.F."
					},
					{
						type: "text",
						name: "iban",
						label: "IBAN"
					},
					{
						type: "text",
						name: "website",
						label: "Sito web"
					}, {
						type: "checkbox",
						label: "Nascondi dall'app",
						name: "hide",

					}
				]}
				onSubmit={(values) => {
					values.type = 1001;
					values.pending = true;
					values.denSociale = values.name;
					this.props.save(values);
					this.closeModal();
				}}
				onClose={this.closeModal}
				title={shop ? `Modifica ${shop.name}` : "Crea"}
			/>
		);
	};

	render() {
		return (
			<div className="animated fadeIn">
				{this.state.modal}
				<Row>
					<NTMButton primary onClick={() => this.edit()}>Aggiungi</NTMButton>
				</Row>
				<Row>
					<Col>
						<NTMTable
							title={"Fondi esterni"}
							header={
								[{
									Header: 'Intestazione ente',
									accessor: 'name' // String-based value accessors!
								}, {
									Header: 'Città',
									accessor: 'city',
								}, {
									Header: 'Indirizzo',
									accessor: 'address'
								}, {
									Header: 'Email di contatto',
									accessor: 'email'
								}, {
									Header: 'P. IVA / C.F.',
									accessor: 'piva'
								}, {
									Header: 'IBAN',
									accessor: 'iban'
								}, {
									Header: 'Sito web',
									accessor: 'website'
								}, {
									Cell: props => <div>
										<NTMButton primary outline
										           onClick={() => this.edit(props.original)}
										>
											<i className={"fa fa-pencil-square"}/>
										</NTMButton>
										<NTMButton red onClick={() => this.delete(props.original)}>
											<i className={"fa fa-trash"}/>
										</NTMButton>
									</div>
								}]}
							filterable
							body={this.props.shops.filter(s => s.type === 1001)}
						/>
					</Col>
				</Row>
			</div>
		);
	}
};
