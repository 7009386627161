import {firebaseDatabase} from '../../firebase';
import {success, error, loading, CLEAR} from "./productsActionsCreator";
import {Client, GENERIC_PRODUCT, PRODUCT_CATEGORY} from "../utils/Client";

export const GET_GENERICS = "GET_GENERICS";
export const GET_CATEGORIES = "GET_CATEGORIES";

let client = new Client();


/*
* Redux action that defines the Load data profile action
*
* Input parameters:
*		user: object, object containing the data of the logged in user
*
* Output:
*   Object with three fields:
*     type: string const value
*     payload: object user
*
* */
export function list() {
	return dispatch => {
		let genericsRef = firebaseDatabase.ref("generic_product");
		genericsRef.on("value", data => {
			let array = [];
			data.forEach((childSnapshot) => {
				let item = childSnapshot.val();
				array.push(item);
			});
			dispatch({
				type: GET_GENERICS,
				data: array
			});
		}, err => {
			console.log(err);
		});
		let categoriesRef = firebaseDatabase.ref("product_category");
		categoriesRef.on("value", data => {
			let array = [];
			data.forEach((childSnapshot) => {
				let item = childSnapshot.val();
				array.push(item);
			});
			dispatch({
				type: GET_CATEGORIES,
				data: array
			});
		}, err => {
			console.log(err);
		});
	};
}

export function saveCategory(category) {
	return dispatch => {
		dispatch(loading());
		let promise = category.id ?
			client.update(category, PRODUCT_CATEGORY) :
			client.save(category, PRODUCT_CATEGORY);
		promise
			.then(response => {
				dispatch(success());
			})
			.catch(e => manageError(e, dispatch));
	};
}

export function deleteCategory(category) {
	return dispatch => {
		dispatch(loading());
		client.delete(category, PRODUCT_CATEGORY)
			.then(response => {
				dispatch(success());
			})
			.catch(e => manageError(e, dispatch));
	};
}

export function saveGenericProduct(product) {
	return dispatch => {
		dispatch(loading());
		let promise = product.id ?
			client.update(product, GENERIC_PRODUCT) :
			client.save(product, GENERIC_PRODUCT);
		promise
			.then(response => {
				dispatch(success());
			})
			.catch(e => manageError(e, dispatch));
	};
}

export function deleteGenericProduct(generic) {
	return dispatch => {
		dispatch(loading());
		client.delete(generic, GENERIC_PRODUCT)
			.then(response => {
				dispatch(success());
			})
			.catch(e => manageError(e, dispatch));
	};
}

export function clear() {
	return {
		type: CLEAR
	};
}

function manageError(e, dispatch) {
	dispatch(loading(false));
	if (e.response) {
		// The request was made and the server responded with a status code
		// that falls out of the range of 2xx
		dispatch(error(e.response.data.message));
	} else {
		// Something happened in setting up the request that triggered an Error
		console.log('Error', e.message);
		dispatch(error(e.message));
	}
}