//Lista delle voci del menu
//Aggiungi un elemento nell'array per avere una nuova voce
// Per le icone puoi scegliere tra fontawesome e simple line icons

export default {
	items: [
		{
			name: "Dashboard",
			url: "/dashboard",
			icon: "icon-speedometer"
		},
		{
			name: "Analytics",
			url: "/analytics",
			icon: "icon-graph"
		},
		{
			name: "Download",
			url: "/download",
			icon: "fa fa-cloud-download",
			reserved: true
		},
		{
			name: "Devices",
			url: "/devices",
			icon: "fa fa-microchip"
		},
    {
      name: "Venues",
      url: "/venues",
      icon: "fa fa-building"
    }
	]
};

