import React, {Component} from "react";

import './_modal.scss';
import ReactLoading from 'react-loading';
import SweetAlert from "react-bootstrap-sweetalert";

export default class NTMModal extends Component {

	render() {
		return (
			<SweetAlert
				title="Loading"
				onConfirm={null}
				showConfirm={false}
			>
				<ReactLoading type={"spokes"}  style={{margin:"auto", color:"green", height:"5rem", width:"5rem"}}/>
			</SweetAlert>
		);
	}
}