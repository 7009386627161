import React, {Component} from "react";
import {
	Alert,
	Container,
	Row,
	Col,
	CardGroup,
	Card,
	CardBody,
	Form,
	Input, Label, FormGroup
} from "reactstrap";
import {LoginButton} from './LoginButton';
import ModalRegistration from '../Registration/ModalRegistration';

export class Login extends Component {

	constructor(props) {
		super(props);
		this.toggleSignUpModal = this.toggleSignUpModal.bind(this);
		this.state = {
			modal: false
		};
	}

	toggleSignUpModal() {
		this.setState({
			modal: !this.state.modal
		});

		return this.state.modal;
	}

	render() {
		return (
			<div className="app flex-row align-items-center">
				<Container>
					<Row className="justify-content-center">
						<Col md="8">
							<CardGroup className="mb-0">
								<Card className="p-4">
									<CardBody className="card-body">
										<img style={{
											width: "50%", display: "block",
											marginLeft: "auto",
											marginRight: "auto"
										}} src={require("../../img/logo.png")}/>
										<Form onSubmit={this.props.onSubmit}>
											{this.props.error && <Alert color="danger">{this.props.error}</Alert>}
											<FormGroup className="mb-3">
												<Label for={"username"}>Email</Label>
												<Input type="text" id={"username"}
												       placeholder="Username" value={this.props.username}
												       onChange={this.props.onUsernameChange} required/>
											</FormGroup>
											<FormGroup className="mb-4">
												<Label for={"password"}>Password</Label>
												<Input className={"login-input-field"} type="password" id={"password"}
												       placeholder="Password" value={this.props.password}
												       onChange={this.props.onPasswordChange} required/>
											</FormGroup>
											<Row>
												<Col xs="6">
													<LoginButton primary className="px-4"
													             isLoading={this.props.isLoading}>Login</LoginButton>
												</Col>
											</Row>
										</Form>
									</CardBody>
								</Card>
							</CardGroup>
						</Col>
					</Row>
				</Container>
				<ModalRegistration
					isOpen={this.state.modal}
					toggleSignUpModal={this.toggleSignUpModal}
					title={"Radom sign-up"}
					message={"Enter your details to create a Radom account"}
				/>
			</div>
		);
	}
}

export default Login;
