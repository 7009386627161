import {ROOT_URL} from '../constants';
import {firebaseAuth} from '../../firebase';

let axios = require('axios');

export const PRODUCT_CATEGORY = "product-categories";
export const GENERIC_PRODUCT = "generic-products";
export const BANK_ACCOUNTS = "dioceses/bank-accounts";
export const SETTINGS = "settings";
export const DIOCESE = "dioceses";
export const CARDS = "cards";
export const INVOICES = "invoices/closed";
export const DIOCESE_INVOICES = "invoices/diocese";
export const DIOCESE_CLOSED_INVOICES = "invoices/diocese/closed";
export const APPACUTIS_INVOICES = "invoices/appacutis/closed";
export const APPACUTIS_OPEN_INVOICE = "invoices/appacutis";
export const APPACUTIS_TRANSACTIONS = "transactions";
export const USERS = "users";
export const SHOPS = "shops";

export class Client {

	constructor() {
		this.instance = axios.create({
			baseURL: ROOT_URL
		});
	}

	get = (type, elem = "") => {
		return firebaseAuth.currentUser.getIdToken(true).then(token => this.instance.get(`${ROOT_URL}${type}/${elem}`, {headers: {'Authorization': 'Bearer ' + token}}));
	};

	save = (element, type) => {
		return firebaseAuth.currentUser.getIdToken(true).then(token => this.instance.post(`${ROOT_URL}${type}`, element, {headers: {'Authorization': 'Bearer ' + token}}));
	};

	update = (element, type) => {
		return firebaseAuth.currentUser.getIdToken(true).then(token => this.instance.put(`${ROOT_URL}${type}/${element.id ? element.id : ""}`, element, {headers: {'Authorization': 'Bearer ' + token}}));
	};

	delete = (element, type) => {
		return firebaseAuth.currentUser.getIdToken(true).then(token => this.instance.delete(`${ROOT_URL}${type}/${element.id}`, {headers: {'Authorization': 'Bearer ' + token}}));
	};

	download = (url, params = {}) => {
		return firebaseAuth.currentUser.getIdToken(true).then(token => this.instance.get(`${ROOT_URL}${url}/`, {
			headers: {'Authorization': 'Bearer ' + token},
			responseType: 'blob',
			params
		}));
	};

	getErrorMessage = (e) => {
		let error = "Unknown error";
		if (e.response) {
			// The request was made and the server responded with a status code
			// that falls out of the range of 2xx
			error = e.response.data.error;
		} else {
			// Something happened in setting up the request that triggered an Error
			error = e.message || e.error;
		}
		return error;
	};
}

export function get(url, config = {elem: "", params: {}, header: {}}) {
	return firebaseAuth.currentUser.getIdToken(true)
		.then(token => axios.get(getUrl(url, config.elem), getConfig(config, token)).then(({data}) => data));
}

function getConfig({params = {}, headers = {}}, token) {
	if (token) headers['Authorization'] = `Bearer ${token}`;
	return {
		params: params,
		headers
	};
}

function getUrl(url, elem) {
	return elem ? `${ROOT_URL}${url}/${elem}` : `${ROOT_URL}${url}`;
}
