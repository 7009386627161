import React, {useState} from "react";
import {Col, Row} from "reactstrap";
import NTMButton from "../components/Buttons";
import NTMTable from "../components/Table/NTMTable";
import {useDispatch, useSelector} from "react-redux";
import {useQueryClient, useQuery} from "react-query";
import {Client} from "../redux/utils";
import {BANK_ACCOUNTS} from "../redux/utils/Client";
import NTMLoadingModal from "../components/Modal/NTMLoadingModal";
import NTMFormModal from "../components/Modal/NTMFormModal";
import {
	clear
} from "../redux/cards/cardsActions";
import {ConfirmDialog, ErrorDialog, SuccessDialog} from "@nextome/messages";
import EditUserModal from "./Users/EditUserModal";
import {updateUser} from "../redux/users/userActions";

let client = new Client();

export default function BankAccounts() {

	let [modal, setModal] = useState(null);
	let {
		data: list,
		isLoading
	} = useQuery("bank-accounts", () => client.get(BANK_ACCOUNTS).then(response => response.data));

	let users = useSelector(store => store.users.list);
	let dioceses = useSelector(store => store.cards.dioceses);
	let dispatch = useDispatch();
	let queryClient = useQueryClient();

	function closeModal() {
		setModal(null);
	}

	function editBankAccount(bankaccount) {
		setModal(
			<NTMFormModal
				initialValues={bankaccount}
				formData={[
					{
						type: "text",
						name: "header",
						label: "Intestazione IBAN*",
						props: {
							required: true
						}
					}, {
						type: "text",
						label: 'IBAN*',
						name: 'iban',
						props: {
							required: true
						}
					}, {
						type: "text",
						label: 'Descrizione*',
						name: 'description',
						props: {
							required: true
						}
					}, {
						label: 'Ente associato*',
						name: 'diocese',
						props: {
							required: true
						},
						options: ["-"].concat(dioceses.filter(d => d.type === "hanging").map(d => {
							return {name: d.name, value: d.id};
						}))
					}, {
						type: "region",
						label: 'Regione*',
						name: 'region',
						props: {
							required: true
						}
					}, {
						type: "region",
						label: 'Provincia (Sigla)*',
						name: 'province',
						props: {
							required: true
						}
					}, {
						type: "region",
						label: 'Città*',
						name: 'city',
						props: {
							required: true
						}
					}, {
						type: "text",
						name: "website",
						label: "Sito web"
					}, {
						type: "text",
						label: 'Causale Pagamenti*',
						name: 'causal',
						props: {
							required: true
						}
					}, {
						label: 'Supervisore',
						name: 'supervisor',
						options: bankaccount ? [{name: "-"}].concat(users.filter(u => u.dioceseId === bankaccount.diocese).map(user => {
							return {name: `${user.firstName} ${user.lastName}`, value: user.uid};
						})) : [{name: "-"}]
					}, {
						type: "checkbox",
						label: "Nascondi dall'app",
						name: "hide",

					}
				]}
				onSubmit={values => {
					Object.keys(values).forEach(k => {
						if (values[k] === "") values[k] = null;
					});
					setModal(<NTMLoadingModal/>);

					let promise;
					if (values.id) promise = client.update(values, BANK_ACCOUNTS);
					else promise = client.save(values, BANK_ACCOUNTS);

					promise.then(() => {
						getSuccessMessage("Salvato");
						queryClient.invalidateQueries("bank-accounts");
					}).catch(e => setModal(<ErrorDialog
						message={e.response ? e.response.data.message : e.message}
						onClose={() => {
							dispatch(clear());
							closeModal();
						}}
					/>));
				}}
				onClose={closeModal}
				title={bankaccount ? "Modifica " + bankaccount.name : "Crea"}
			/>
		);
	}

	function deleteBankAccount(bankAccount) {
		setModal(
			<ConfirmDialog
				message={`Vuoi eliminare il Fondo Sospeso ${bankAccount.header}?`}
				onCancel={closeModal}
				onConfirm={() => {
					client.delete(bankAccount, BANK_ACCOUNTS).then(() => {
						getSuccessMessage("Eliminato");
						queryClient.invalidateQueries("bank-accounts");
					});
					closeModal();
				}}
			/>
		);
	}

	function getSuccessMessage(title) {
		setModal(<SuccessDialog onConfirm={closeModal} title={title}/>);
	}

	function UserCell(uid) {
		if (!uid) return <div>-</div>;
		let user = users.find(u => uid === u.uid);

		if (!user) return <div>{"Sconosciuto"}</div>;
		let username = `${user.firstName} ${user.lastName}`;
		return (
			<div
				className={"userCell"}
				onClick={() => {
					setModal(
						<EditUserModal
							user={user}
							onSubmit={(values) => {
								dispatch(updateUser(values));
								closeModal();
							}}
							onClose={closeModal}
						/>);
				}}>
				{username}
			</div>);
	}

	return <div className="animated fadeIn">
		{modal}
		{isLoading && <NTMLoadingModal/>}
		<Row>
			<NTMButton primary onClick={() => editBankAccount()}>{"CREA"}</NTMButton>
		</Row>
		<Row>
			<Col>
				<NTMTable
					title={"Fondi Sospesi"}
					header={
						[{
							Header: 'Intestazione IBAN',
							accessor: 'header' // String-based value accessors!
						}, {
							Header: 'IBAN',
							accessor: 'iban',
						}, {
							Header: 'Descrizione',
							accessor: 'description'
						}, {
							Header: 'Città',
							accessor: 'city',
							Cell: props => <div>{`${props.original.city} (${props.original.province})`}</div>
						}, {
							Header: 'Ente Fondi',
							accessor: 'diocese',
							Cell: props =>
								<div>{dioceses && dioceses.find(d => d.id === props.original.diocese)?.name}</div>
						}, {
							Header: 'Supervisore',
							accessor: 'supervisor',
							Cell: props => UserCell(props.original.supervisor)
						}, {
							accessor: 'details',
							Cell: props => <div>
								<NTMButton primary outline
								           onClick={() => editBankAccount(props.original)}
								>
									<i className={"fa fa-pencil-square"}/>
								</NTMButton>
								<NTMButton red
								           onClick={() => deleteBankAccount(props.original)}
								>
									<i className={"fa fa-trash"}/>
								</NTMButton>
							</div>
						}]}
					defaultPageSize={10}
					body={list}
				/>
			</Col>
		</Row>
	</div>;

}
