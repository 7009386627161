import {CLEAR, LOADING, ERROR, SUCCESS, GET_SETTINGS} from "./settingsActionsCreator";


const defaultState = {
	loading: false,
	success: false
};

export default function (state = defaultState, action) {
	switch (action.type) {
		case LOADING: {
			return {
				...state,
				loading: action.data
			};
		}
		case ERROR: {
			return {
				...state,
				error: action.data
			};
		}
		case SUCCESS: {
			return {
				...state,
				loading: false,
				success: true,
				data: action.data
			};
		}
		case GET_SETTINGS : {
			return {
				...state,
				loading: false,
				data: action.data
			};
		}
		case CLEAR: {
			return {
				...state,
				loading: false,
				success: false,
				error: null
			};
		}

	}
	return state;
}