import {
	LOADING,
	ERROR,
	SUCCESS,
	GET_CARDS,
	ADD_CARD,
	EDIT_CARD,
	REMOVE_CARD,
	CLEAR,
	ADD_DIOCESE,
	GET_DIOCESES,
	EDIT_DIOCESE,
	REMOVE_DIOCESE, UPDATE_DIOCESE_INVOICE, GET_CLOSED_DIOCESE_INVOICES, GET_OPEN_DIOCESE_INVOICES
} from "./cardsActionsCreator";


const defaultState = {
	loading: false,
	list: [],
	dioceses: [],
	openinvoices: {},
	closedinvoices: {}
};

export default function (state = defaultState, action) {
	switch (action.type) {
		case LOADING: {
			return {
				...state,
				loading: action.data
			};
		}
		case GET_CARDS: {
			return {
				...state,
				list: action.data,
				loading: false
			};
		}
		case ADD_CARD: {
			let list = [...state.list];
			let index = list.findIndex(c => c.cardId === action.data.cardId);
			if(index > -1) list[index] = action.data;
			else list.push(action.data);
			return {
				...state,
				list,
				loading: false
			};
		}
		case REMOVE_CARD: {
			let list = [...state.list];
			list = list.filter(l => l.cardId !== action.data.cardId);
			return {
				...state,
				list,
				loading: false
			};
		}
		case EDIT_CARD: {
			let list = [...state.list];
			let index = list.findIndex(d => d.cardId === action.data.cardId);
			list[index] = action.data;
			return {
				...state,
				list,
				loading: false
			};
		}
		case ERROR: {
			return {
				...state,
				loading: false,
				error: action.data
			};
		}
		case SUCCESS: {
			return {
				...state,
				loading: false,
				success: true
			};
		}
		case CLEAR: {
			return {
				...state,
				loading: false,
				success: false,
				error: null
			};
		}
		case GET_DIOCESES: {
			return {
				...state,
				dioceses: action.data,
				loading: false
			};
		}
		case ADD_DIOCESE: {
			let dioceses = [...state.dioceses];
			let index = dioceses.findIndex(c => c.id === action.data.id);
			if(index > -1) dioceses[index] = action.data;
			else dioceses.push(action.data);
			return {
				...state,
				dioceses,
				loading: false
			};
		}
		case REMOVE_DIOCESE: {
			let dioceses = [...state.dioceses];
			dioceses = dioceses.filter(d => d.id !== action.data.id);
			return {
				...state,
				dioceses,
				loading: false
			};
		}
		case EDIT_DIOCESE: {
			let dioceses = [...state.dioceses];
			let index = dioceses.findIndex(d => d.id === action.data.id);
			dioceses[index] = action.data;
			return {
				...state,
				dioceses,
				loading: false
			};
		}
		case UPDATE_DIOCESE_INVOICE: {

			let invoices = Object.assign({}, state.closedinvoices);
			let diocese = invoices[action.data.diocese];

			if (diocese) {
				let index = diocese.findIndex(d => d.invoiceNumber === action.data.id);
				if (diocese[index])
					diocese[index][action.data.type] = action.data.url;
				invoices[action.data.diocese] = diocese;
			}

			return {
				...state,
				closedinvoices:invoices,
				loading: false
			};
		}
		case GET_CLOSED_DIOCESE_INVOICES: {
			let invoices = Object.assign({}, state.closedinvoices);
			invoices[action.data.diocese] = action.data.list;
			return {
				...state,
				closedinvoices:invoices
			};
		}
		case GET_OPEN_DIOCESE_INVOICES: {
			let invoices = Object.assign({}, state.openinvoices);
			invoices[action.data.diocese] = action.data.list;
			return {
				...state,
				openinvoices:invoices
			};
		}
	}
	return state;
}