import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import {Badge, Nav, NavItem} from 'reactstrap';
import classNames from 'classnames';
import nav from './_nav';
import navUser from './_navUser';

class Sidebar extends Component {

	handleClick(e) {
		e.preventDefault();
		e.target.parentElement.classList.toggle('open');
	}

	activeRoute(routeName, props) {
		// return this.props.location.pathname.indexOf(routeName) > -1 ? 'nav-item nav-dropdown open' : 'nav-item nav-dropdown';
		return props.location.pathname.indexOf(routeName) > -1 ? 'nav-item nav-dropdown open' : 'nav-item nav-dropdown';
	}

	// todo Sidebar nav secondLevel
	// secondLevelActive(routeName) {
	//   return this.props.location.pathname.indexOf(routeName) > -1 ? "nav nav-second-level collapse in" : "nav nav-second-level collapse";
	// }

	render() {

		const props = this.props;
		const activeRoute = this.activeRoute;
		const handleClick = this.handleClick;

		// badge addon to NavItem
		const badge = (badge) => {
			if (badge) {
				const classes = classNames(badge.class);
				return (<Badge className={classes} color={badge.variant}>{badge.text}</Badge>);
			}
		};

		// simple wrapper for nav-title item
		const wrapper = item => {
			return (!item.wrapper ? item.name : (React.createElement(item.wrapper.element, item.wrapper.attributes, item.name)));
		};

		// nav list section title
		const title = (title, key) => {
			const classes = classNames("nav-title", title.class);
			return (<li key={key} className={classes}>{wrapper(title)} </li>);
		};

		// nav list divider
		const divider = (divider, key) => (<li key={key} className="divider"/>);

		// nav item with nav link
		const navItem = (item, key) => {
			const classes = classNames("nav-link", item.class);
			if (item.reserved && !this.props.isAdmin) return null;
			return (
				<NavItem key={key}>
					<NavLink to={item.url} className={classes} activeClassName="active">
						<i className={item.icon}/>{item.name}{badge(item.badge)}
					</NavLink>
				</NavItem>
			);
		};

		// nav dropdown
		const navDropdown = (item, key) => {
			return (
				<li key={key} className={activeRoute(item.url, props)}>
					<a className="nav-link nav-dropdown-toggle" href="/" onClick={handleClick.bind(this)}><i
						className={item.icon}/> {item.name}</a>
					<ul className="nav-dropdown-items">
						{navList(item.children)}
					</ul>
				</li>);
		};
		// nav link
		const navLink = (item, idx) =>
			item.title ? title(item, idx) :
				item.divider ? divider(item, idx) :
					item.children ? navDropdown(item, idx)
						: navItem(item, idx);

		// nav list
		const navList = (items) => {
			return items.map((item, index) => navLink(item, index));
		};

		// sidebar-nav root
		return (
			<div className="sidebar">
				<nav className="sidebar-nav">
					<Nav>
						{this.props.userIsAdmin ? navList(nav.items) : navList(navUser.itemsUser)}
					</Nav>
				</nav>
			</div>
		);
	}
}

export default Sidebar;
