export const SUCCESS = "SUCCESS_CARD";
export const ERROR = "ERROR_CARD";
export const CLEAR = "CLEAR_CARDS";
export const LOADING = "CARDS_LOADING";

export const ADD_CARD = "ADD_CARD";
export const EDIT_CARD = "EDIT_CARD";
export const GET_CARDS = "GET_CARDS";
export const REMOVE_CARD = "REMOVE_CARD";

export const GET_DIOCESES = "GET_DIOCESES";
export const ADD_DIOCESE = "ADD_DIOCESE";
export const REMOVE_DIOCESE = "REMOVE_DIOCESE";
export const EDIT_DIOCESE = "EDIT_DIOCESE";
export const UPDATE_DIOCESE_INVOICE = "UPDATE_DIOCESE_INVOICE";
export const GET_OPEN_DIOCESE_INVOICES = "GET_OPEN_DIOCESES_INVOICES";
export const GET_CLOSED_DIOCESE_INVOICES = "GET_CLOSED_DIOCESE_INVOICES";

export function loading(loading = true) {
	return {
		type: LOADING,
		data: loading
	};
}

export function error(error) {
	return {
		type: ERROR,
		data: error
	};
}


export function success() {
	return {
		type: SUCCESS,
	};
}

export function clear() {
	return {
		type: CLEAR
	};
}

export function addCard(card) {
	return {
		type: ADD_CARD,
		data: card
	};
}

export function editCard(card) {
	return {
		type: EDIT_CARD,
		data: card
	};
}

export function removeCard(card) {
	return {
		type: REMOVE_CARD,
		data: card
	};
}
export function listDiocesesCreator(dioceses) {
	return {
		type: GET_DIOCESES,
		data: dioceses
	};
}

export function addDiocese(diocese) {
	return {
		type: ADD_DIOCESE,
		data: diocese
	};
}

export function removeDiocese(diocese) {
	return {
		type: REMOVE_DIOCESE,
		data: diocese
	};
}

export function editDiocese(diocese) {
	return {
		type: EDIT_DIOCESE,
		data: diocese
	};
}

export function saveOpenInvoices(diocese, invoices) {
	return {
		type: GET_OPEN_DIOCESE_INVOICES,
		data: {diocese, list: invoices}
	};
}
export function saveClosedInvoices(diocese, invoices) {
	return {
		type: GET_CLOSED_DIOCESE_INVOICES,
		data: {diocese, list: invoices}
	};
}

export function updateStorageLink(diocese, id, type, url) {
	return {
		type: UPDATE_DIOCESE_INVOICE,
		data: {diocese, id, type, url}
	};
}