import React, {useEffect, useState} from "react";
import moment from "moment";
import EditUserModal from "./Users/EditUserModal";
import {useSelector} from "react-redux";
import {Client} from "../redux/utils";
import {APPACUTIS_INVOICES, APPACUTIS_OPEN_INVOICE, APPACUTIS_TRANSACTIONS, USERS} from "../redux/utils/Client";
import {useSnackbar} from "notistack";
import NTMButton from "../components/Buttons";
import Section from "../components/Section";
import Table from "../components/Table";
import SweetAlert from "react-bootstrap-sweetalert";
import {firebaseStorage} from "../firebase";
import {MoneyCell, TimestampCell} from "../components/Table/tableCells";

let client = new Client();

export default function AdminPage() {

	let [modal, setModal] = useState();
	let [openInvoice, setOpenInvoice] = useState();
	let [lastInvoiceNumber, setLastInvoiceNumber] = useState();
	let [invoices, setInvoices] = useState([]);
	let [transactions, setTransactions] = useState([]);
	let users = useSelector(store => store.users.list);
	const {enqueueSnackbar} = useSnackbar();

	useEffect(() => {
		getInvoices();
		getOpenInvoice();
		client.get(APPACUTIS_TRANSACTIONS)
			.then(({data}) => setTransactions(data))
			.catch(manageError);
	}, []);

	function manageError(e) {
		enqueueSnackbar(client.getErrorMessage(e), {variant: "error"});
	}

	function closeModal() {
		setModal(null);
	}

	function getOpenInvoice() {
		client.get(APPACUTIS_OPEN_INVOICE)
			.then(({data}) => {
				data.startDate = data.startDate && moment(data.startDate).format("DD/MM/YYYY");
				setOpenInvoice(data);
			})
			.catch(manageError);
	}

	function updateStorageLink(number, field, value) {
		let newInvoices = invoices.slice();
		let index = invoices.findIndex(d => d.invoiceNumber === number);
		console.log(invoices, newInvoices);
		if (index > -1)
			newInvoices[index][field] = value;
		setInvoices(newInvoices);
	}

	function getInvoices() {
		client.get(APPACUTIS_INVOICES)
			.then(async ({data}) => {
				for (let i of data) {
					i.invoiceDate = i.invoiceDate && moment(i.invoiceDate).format("DD/MM/YYYY");
					if (i.link)
						i.link = await firebaseStorage.refFromURL(i.link).getDownloadURL();

				}
				data.sort((a, b) => sort(a.invoiceNumber, b.invoiceNumber));
				let lastInvoiceNumber = 0;
				if (data.length > 0) {
					console.log(data)
					let year = data[0].invoiceNumber.split("/")[1];
					if (parseInt(year) === new Date().getFullYear())
						lastInvoiceNumber = parseInt(data[0].invoiceNumber.split("/")[0]);
				}

				setLastInvoiceNumber(lastInvoiceNumber);
				setInvoices(data);
			})
			.catch(manageError);
	}

	function closeInvoice(invoiceId, invoiceNumber) {
		client.save(null, `${APPACUTIS_OPEN_INVOICE}/${invoiceId}/${invoiceNumber}`)
			.then(() => {
				getInvoices();
				getOpenInvoice();
			})
			.catch(manageError)
			.finally(closeModal);
	}

	function sort(a, b) {
		let splitteda = a.split("/");
		let splittedb = b.split("/");
		if (splitteda[1] === splittedb[1]) {
			return splittedb[0] - splitteda[0];
		} else return splittedb[1] - splitteda[1];
	}

	function userCell(donor) {
		let user = users.find(u => donor === u.uid);

		if (!user) return <div>{"Sconosciuto"}</div>;
		let username;
		if (!user.firstName && !user.lastName) username = "Anonimo";
		else username = `${user.firstName} ${user.lastName}`;

		return (
			<div
				className={"userCell"}
				onClick={() => setModal(<EditUserModal
					user={user}
					onSubmit={(user) => {
						client.update(user, `${USERS}/${user.uid}`)
							.then(() => {
								enqueueSnackbar("Saved", {variant: "success"});
							})
							.catch(manageError)
							.finally(closeModal);
					}}
					onClose={closeModal}
				/>)}>
				{username}
			</div>);
	}

	return (
		<div className="animated fadeIn card">
			{modal}
			{openInvoice &&
			<Section
				title={"fattura aperta"}
				data={[
					{
						label: "Inizio periodo fatturazione",
						value: openInvoice.startDate
					},
					{
						label: "Donazioni effettuate",
						value: openInvoice.invoiceBody && openInvoice.invoiceBody.quantity
					},
					{
						label: "Totale Fattura Ivato",
						value: openInvoice.invoiceBody && openInvoice.totalInvoice
					},
					{
						label:
							<NTMButton primary
							           onClick={() => setModal(
								           <SweetAlert
									           showCancel
									           confirmBtnText={"Conferma"}
									           confirmBtnBsStyle={"danger"}
									           cancelBtnText={"Annulla"}
									           cancelBtnBsStyle={"default"}
									           title={"Confermi?"}
									           onConfirm={() => {
										           closeInvoice(openInvoice.invoiceId, lastInvoiceNumber + 1);
									           }}
									           onCancel={closeModal}>
									           {`Il numero d'ordine sarà ${lastInvoiceNumber + 1}/${new Date().getFullYear()}`}
								           </SweetAlert>
							           )}
							>
								{"Chiudi Fattura"}
							</NTMButton>
					}
				]}
			/>
			}
			<Table
				title={"Fatture emesse"}
				header={[
					{
						Header: "Numero d'ordine",
						accessor: 'invoiceNumber',
						sortMethod: sort
					},
					{
						Header: "Data fattura",
						accessor: 'invoiceDate',
					},
					{
						Header: "Totale",
						accessor: 'value'
					},
					{
						Header: 'Scarica',
						Cell: props => <div>
							{
								props.original.link && <a className={"btn"} href={props.original.link}>
									<i className="fa fa-file-pdf-o" aria-hidden="true"></i>
								</a>
							}
							{
								props.original.eInvoiceLink && <a className={"btn"} href={props.original.eInvoiceLink}>
									<i className="fa fa-file-excel-o" aria-hidden="true"></i>
								</a>
							}

						</div>
					}
				]}
				body={invoices}
			/>
			<Table
				title={"Ripartizioni"}
				header={[
					{
						Header: "Donatore",
						accessor: "user",
						Cell: props => userCell(props.original.user),
						sortMethod: (a, b) => {
							let a_u = a.firstName ? `${a.firstName} ${a.lastName}` : "sconosciuto";
							let b_u = b.firstName ? `${b.firstName} ${b.lastName}` : "sconosciuto";
							return a_u.toLocaleLowerCase().localeCompare(b_u.toLocaleLowerCase());
						}
					},
					{
						Header: "Dispositivo",
						accessor: "device"
					},
					{
						Header: "Data",
						accessor: "timestamp",
						width: 180,
						Cell: props => <TimestampCell value={props.original.timestamp}/>
					},
					{
						Header: "Tipo",
						accessor: 'type',
						Cell: props => props.original.type === 'externalFund' ? "f. esterno" : props.original.type === 'hangingFund' ? "f. sospeso" : props.original.type
					},
					{
						Header: "Destinazione",
						Cell: props => props.original.partner ? props.original.partnerName : props.original.diocese ? props.original.dioceseName : "-"
					},
					{
						Header: 'Tot',
						accessor: "totTransaction",
						Cell: props => <MoneyCell value={props.original.totTransaction}/>
					},
					{
						Header: 'FdSos',
						accessor: "totSupport",
						Cell: props => <MoneyCell value={props.original.totSupport}/>
					},
					{
						Header: 'FdSol',
						accessor: "totSolidarity",
						Cell: props => <MoneyCell value={props.original.totSolidarity}/>
					},
					{
						Header: 'FdF',
						accessor: "totGuarantee",
						Cell: props => <MoneyCell value={props.original.totGuarantee}/>
					},
					{
						Header: 'FdRac',
						accessor: "totCollection",
						Cell: props => <MoneyCell value={props.original.totCollection}/>
					},
					{
						Header: 'Ripartita',
						accessor: 'dividedTimestamp',
						width: 180,
						Cell: props => <TimestampCell value={props.original.dividedTimestamp}/>
					}
				]}
				body={transactions}
			/>
		</div>
	);
}
