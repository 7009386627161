import {GET_CATEGORIES, GET_GENERICS} from "./productsActions";
import {CLEAR, LOADING, ERROR, SUCCESS} from "./productsActionsCreator";

const images = {
	Alimentari: "",
	Bar: "",
	Fastfood: "",
	Fruttivendolo: "",
	Panificio: "",
	Paninoteca: "",
	Pizzeria: "",
	Rosticceria: "",
	"Tavola Calda": "",
	Altro: "",
};

const defaultState = {
	categories: [],
	generics: []
};

export default function (state = defaultState, action) {
	switch (action.type) {
		case GET_CATEGORIES: {
			return {
				...state,
				categories: action.data
			};
		}
		case GET_GENERICS: {
			return {
				...state,
				generics: action.data
			};
		}
		case LOADING: {
			return {
				...state,
				loading: action.data
			};
		}
		case ERROR: {
			return {
				...state,
				error: action.data
			};
		}
		case SUCCESS: {
			return {
				...state,
				loading: false,
				success: true
			};
		}
		case CLEAR: {
			return {
				...state,
				loading: false,
				success: false,
				error: null
			};
		}

	}
	return state;
}