import React, {useEffect, useState} from "react";
import moment from "moment";
import {useSelector} from "react-redux";
import {useQuery} from "react-query";
import {useSnackbar} from "notistack";
import {Client} from "../redux/utils";
import {
	SHOPS,
	INVOICES,
	get
} from "../redux/utils/Client";
import {firebaseStorage} from "../firebase";
import {useParams} from "react-router-dom";
import Section from "../components/Section";
import Table from "../components/Table";
import {Col, Row} from "reactstrap";
import {MoneyCell, TimestampCell} from "../components/Table/tableCells";
import TabSelector from "../components/TabSelector";

import {DateRangePicker} from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';

let client = new Client();

export default function ShopPage() {

	let [modal, setModal] = useState();
	let [erogations, setErogations] = useState([]);
	let [invoices, setInvoices] = useState([]);
	let [tab, setTab] = useState("invoices");
	let [startDate, setStartDate] = useState(moment().year(2021).dayOfYear(1));
	let [endDate, setEndDate] = useState(moment());
	let [focusedInput, setFocusedInput] = useState();
	let users = useSelector(store => store.users.list);
	let categories = useSelector(store => store.shops.categories);
	let products = useSelector(store => store.products);
	let {shopid} = useParams();
	let {
		data: shops
	} = useQuery("shops", () => client.get(SHOPS).then(response => response.data));
	let {
		data: shopProducts
	} = useQuery(["products", {shopid}], () => client.get(`${SHOPS}/${shopid}/products`).then(response => response.data));
	const {enqueueSnackbar} = useSnackbar();
	const shop = shops ? shops.find(s => s.id.toString() === shopid) : {};
	const cat = categories.find(c => c.id === shop.type);
	let owner = users.find(u => u.partner_venue && u.partner_venue.toString() === shopid);

	useEffect(() => {
		getInvoices();
		getErogations();
	}, []);
	useEffect(() => {
		if (!focusedInput) {
			getErogations();
		}
	}, [startDate, endDate]);

	function manageError(e) {
		enqueueSnackbar(client.getErrorMessage(e), {variant: "error"});
	}

	function getInvoices() {
		client.get(INVOICES, shopid)
			.then(({data}) => {
				data.sort((a, b) => sort(a.invoiceNumber, b.invoiceNumber));
				data.forEach(async i => i.httpLink = await getDownloadURL(i.link));
				setInvoices(data);
			})
			.catch(manageError);
	}

	async function getDownloadURL(link) {
		// if (!link) return "";
		return await firebaseStorage.refFromURL(link).getDownloadURL();
	}

	function getErogations() {
		get(`erogations`, {
			params: {
				shop: shopid,
				start: startDate.format("yyyy-MM-DD"),
				end: endDate.format("yyyy-MM-DD"),
			}
		}).then(data => setErogations(data));
	}

	function sort(a, b) {
		let splitteda = a.split("/");
		let splittedb = b.split("/");
		if (splitteda[1] === splittedb[1]) {
			return splittedb[0] - splitteda[0];
		} else return splittedb[1] - splitteda[1];
	}

	function userFilter(filter, row) {
		let user = users.find(u => row[filter.id] === u.partner_venue);
		let username = user ? `${user.firstName} ${user.lastName}` : "Sconosciuto";
		return username && username.toLowerCase().includes(filter.value && filter.value.toLowerCase());
	}

	const genericProductCell = genericId => {
		let generic = products.generics.find(pg => pg.id === genericId);
		return <div>{generic.name || ""}</div>;
	};

	const categoryProductCell = genericId => {
		let generic = products.generics.find(pg => pg.id === genericId);
		let category = products.categories.find(c => c.id === generic.product_category);
		return <div>{category.name || ""}</div>;
	};

	erogations.sort((a, b) => b.erogationDate - a.erogationDate);

	return (
		<div className="animated fadeIn card">
			{modal}
			<Row className="text-center display-4" style={{paddingBottom: "1rem"}}>
				<Col>{shop.name}</Col>
			</Row>
			{shop.id && <Section
				title={shop.denSociale || shop.name}
				data={[
					{
						label: "Indirizzo",
						value: shop.address
					},
					{
						label: "CAP",
						value: shop.cap
					},
					{
						label: "Città",
						value: shop.city
					},
					{
						label: "Email di contatto",
						value: shop.email
					},
					{
						label: "Indirizzo IBAN",
						value: shop.iban
					},
					{
						label: "Partita IVA",
						value: shop.piva
					},
					{
						label: "Provincia",
						value: shop.province
					},
					{
						label: "Categoria",
						value: cat ? cat.name : shop.type
					},
				]}
			/>}
			{owner && <Section
				title={"Proprietario"}
				data={[
					{
						label: "Nome",
						value: owner.firstName
					}, {
						label: "Cognome",
						value: owner.lastName
					}, {
						label: "Tipo di documento",
						value: owner.docType
					}, {
						label: "Numero di documento",
						value: owner.docNumber
					}, {
						label: "Ente rilasciante",
						value: owner.docIssuer
					}, {
						label: "Città",
						value: owner.city
					}, {
						label: "Email",
						value: owner.email
					}, {
						label: "Data di nascita",
						value: owner.birthday
					},]}
			/>}

			<Row style={{padding: "1rem"}}>
				<TabSelector id={"prods"} selected={tab} label={"Prodotti"} setTab={setTab}/>
				<TabSelector id={"invoices"} selected={tab} label={"Fatture"} setTab={setTab}/>
				<TabSelector id={"erogations"} selected={tab} label={"Erogazioni"} setTab={setTab}/>
			</Row>

			{tab === "invoices" &&
			<Table
				title={"Fatture emesse"}
				header={[
					{
						Header: 'Numero fattura',
						accessor: 'invoiceNumber',
						sortMethod: (a, b) => {
							let splitteda = a.split("/");
							let splittedb = b.split("/");
							if (splitteda[1] === splittedb[1]) {
								return splittedb[0] - splitteda[0];
							} else return splittedb[1] - splitteda[1];
						}
					},
					{
						Header: "Data fattura",
						accessor: 'invoiceDate',
						Cell: props =>
							<div>{props.original.invoiceDate ? moment(props.original.invoiceDate).format("DD/MM/YYYY") : "-"}</div>
					},
					{
						Header: "Totale",
						accessor: 'value',
						Cell: props => <MoneyCell value={props.original.value}/>
					},
					{
						Header: 'Scarica',
						Cell: props => <div>
							{props.original.link ? <a className={"btn"}
							                          href={props.original.httpLink}>
									<i className="fa fa-file-pdf-o" aria-hidden="true"/>
								</a>
								:
								"-"
							}
						</div>
					}
				]}
				body={invoices}
			/>
			}
			{tab === "prods" &&
			<Table
				title={"Prodotti"}
				header={[
					{
						Header: 'Nome',
						accessor: 'productName'
					},
					{
						Header: 'Descrizione',
						accessor: 'description'
					},
					{
						Header: "Prodotto Generico",
						Cell: props => genericProductCell(props.original.generic_product)
					},
					{
						Header: "Categoria",
						Cell: props => categoryProductCell(props.original.generic_product)
					},
					{
						Header: "Prezzo Venditore",
						accessor: 'priceToVendor',
						Cell: props => <MoneyCell value={props.original.priceToVendor}/>
					},
					{
						Header: "Prezzo Pubblico",
						accessor: 'priceToClient',
						Cell: props => <MoneyCell value={props.original.priceToClient}/>
					},
				]}
				body={shopProducts || []}
			/>
			}
			{tab === "erogations" &&
			<>
				<DateRangePicker
					style={styles.ctr}
					startDate={startDate} // momentPropTypes.momentObj or null,
					startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
					endDate={endDate} // momentPropTypes.momentObj or null,
					endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
					onDatesChange={({startDate, endDate}) => {
						setStartDate(startDate);
						setEndDate(endDate);
					}} // PropTypes.func.isRequired,
					focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
					onFocusChange={focusedInput => setFocusedInput(focusedInput)} // PropTypes.func.isRequired
					minimumNights={0}

					startDatePlaceholderText={"Inizio"}
					endDatePlaceholderText={"Fine"}
					displayFormat={"DD/MM/YYYY"}
					isOutsideRange={(o) => o.isAfter(moment(), 'day')}
					small={true}
				/>
				<Table
					title={"Erogazioni"}
					header={[
						{
							Header: 'ID',
							accessor: 'id'
						},
						{
							Header: 'Data',
							accessor: 'erogationDate',
							Cell: props => <TimestampCell value={props.original.erogationDate}/>
						},
						{
							Header: 'Prodotto',
							accessor: 'productName'
						},
						{
							Header: 'Card',
							accessor: 'card'
						},
						{
							Header: 'Beneficiario',
							accessor: 'beneficiaryFirstName',
							Cell: props =>
								<div>{`${props.original.beneficiaryFirstName} ${props.original.beneficiaryLastName}`}</div>
						},
						{
							Header: 'Prezzo di vendita',
							accessor: 'priceToClient',
							Cell: props => <MoneyCell value={props.original.priceToClient}/>
						},
						{
							Header: 'Prezzo di costo',
							accessor: 'priceToVendor',
							Cell: props => <MoneyCell value={props.original.priceToVendor}/>
						},
						{
							Header: 'Sconto applicato',
							Cell: props => <div>{`${((props.original.priceToClient - props.original.priceToVendor) / props.original.priceToClient *100).toFixed(0)}%`}</div>
						},
					]}
					body={erogations || []}
				/>
			</>
			}
		</div>
	);
}
const styles = {
	ctr: {
		marginTop: "auto",
		marginBottom: "auto",
		marginLeft: "1rem"
	}
};
