import React from 'react';
import {ButtonGroup, Card, CardBody} from "reactstrap";

import NTMTable from "../../components/Table/NTMTable";
import NTMButton from "../../components/Buttons/NTMButton";
import {SuccessDialog, ConfirmDialog, ErrorDialog} from "@nextome/messages";
import EditUserModal from "./EditUserModal";
import moment from "moment";
import {Link} from "react-router-dom";

export default class UsersPage extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			modal: false,
			donors: 0,
			partners: 0,
			supervisors: 0,
			fundSuperv: 0,
			beneficiary: 0
		};
	}

	countUsers = (users) => {
		let donors = 0;
		let partners = 0;
		let supervisors = 0;
		let beneficiary = 0;
		let fundSuperv = 0;
		users.forEach(u => {
			switch (u.type) {
				case 1:
					donors++;
					break;
				case 2:
					partners++;
					break;
				case 3:
					supervisors++;
					break;
				case 4:
					beneficiary++;
					break;
				case 5:
					supervisors++;
					break;
				case 6:
					fundSuperv++;
					break;
				case 7:
					fundSuperv++;
					break;
			}
		});
		this.setState({donors, partners, supervisors, beneficiary, fundSuperv});
	};

	componentDidMount() {
		this.countUsers(this.props.users);
	}

	componentWillReceiveProps(nextProps) {
		this.countUsers(nextProps.users);
	}

	closeModal = () => {
		this.setState({
			modal: null
		});
	};

	openModal = (modal) => {
		this.setState({
			modal
		});
	};

	editUser = (user) => {
		this.openModal(
			<EditUserModal
				user={user}
				onSubmit={(values) => {

					if ((user.type === 5 && user.type !== values.type) || (user.type === 3 && values.type === 5)) {
						this.openModal(
							<ErrorDialog
								onClose={this.closeModal}
								message={"Per modificare l'amministratore della diocesi vai dall'apposita pagina 'Diocesi'"}
							/>);
					} else {
						this.props.updateUser(values);
						this.closeModal();
					}
				}}
				onClose={this.closeModal}
			/>);
	};

	getSuccessMessage = (title) => {
		this.openModal(<SuccessDialog onConfirm={this.closeModal} title={title}/>);
	};

	getConfirmMessage = (title, message, buttonText, confirmMessage, onConfirm) => {
		this.openModal(
			<ConfirmDialog
				title={title}
				message={message}
				confirmBtnText={buttonText}
				onCancel={this.closeModal}
				onConfirm={() => {
					onConfirm();
					this.getSuccessMessage(confirmMessage);
				}}
			/>
		);
	};

	findTypeName = type => {
		switch (type) {
			case 1:
				return "Donatore";
			case 2:
				return "Partner";
			case 3:
				return "Supervisore Caritas";
			case 4:
				return "Beneficiario";
			case 5:
				return "Amministr. Diocesi";
			case 6:
				return "Supervisore Fondi";
			case 7:
				return "Amministr. Fondi";
			default:
				return "Superuser";
		}
	};

	render() {
		let data = this.state.selected ? this.props.users.filter(u => this.state.selected === 3 ? u.type === this.state.selected || u.type === 5 : u.type === this.state.selected) : this.props.users;

		let {selected} = this.state;
		let {cards, users} = this.props;

		if (!selected) data = this.props.users;
		else if (selected === 3) data = users.filter(u => u.type === selected || u.type === 5);
		else if (selected === 6) data = users.filter(u => u.type === selected || u.type === 7);

		if (this.state.selected === 4) {
			data.forEach(user => {
				let card = cards.find(c => c.beneficiaryId === user.uid);
				if (card) user.issuer = {name: card.dioceseName, id: card.dioceseId};
			});
		}

		return (
			<Card>
				{this.state.modal}
				<CardBody>
					<div>
						<ButtonGroup>
							<NTMButton
								primary
								onClick={() => this.setState({selected: null})}
								outline={this.state.selected}>
								Tutti ({this.props.users.length})
							</NTMButton>
							<NTMButton
								primary
								onClick={() => this.setState({selected: 1})}
								outline={this.state.selected !== 1}>
								Donatori ({this.state.donors})
							</NTMButton>
							<NTMButton
								primary
								onClick={() => this.setState({selected: 2})}
								outline={this.state.selected !== 2}
							>Partner ({this.state.partners})</NTMButton>
							<NTMButton
								primary
								onClick={() => this.setState({selected: 3})}
								outline={this.state.selected !== 3}
							>Supervisori Caritas
								({this.state.supervisors})</NTMButton>
							<NTMButton
								primary
								onClick={() => this.setState({selected: 6})}
								outline={this.state.selected !== 6}
							>Supervisori Fondi
								({this.state.fundSuperv})</NTMButton>
							<NTMButton
								primary
								onClick={() => this.setState({selected: 4})}
								outline={this.state.selected !== 4}
							>Beneficiari
								({this.state.beneficiary})</NTMButton>
						</ButtonGroup>
					</div>

					<NTMTable className={"table-admin"}
					          header={
						          this.state.selected === 4 ?
							          [
								          {
									          Header: 'Nome',
									          accessor: 'firstName' // String-based value accessors!
								          },
								          {
									          Header: 'Cognome',
									          accessor: 'lastName',
								          },
								          {
									          Header: 'Città',
									          accessor: 'city'
								          },
								          {
									          Header: 'Data di nascita',
									          accessor: 'birthday'
								          },
								          {
									          Header: 'Data di iscrizione',
									          accessor: 'createdAt',
									          Cell: props => <div>
										          {moment(props.original.createdAt).format("DD/MM/YYYY")}
									          </div>,
									          filterable: false
								          },
								          {
									          Header: "Diocesi/fondo",
									          accessor: "issuer.name",
									          Cell: props => <div>
										          {props.original.issuer ?
											          <Link
												          to={"/dioceses/" + props.original.issuer.id}>{props.original.issuer.name}</Link> :
											          "-"
										          }
									          </div>,
								          },
								          {
									          accessor: 'details',
									          filterable: false,
									          Cell: props => <div>
										          <NTMButton primary outline
										                     onClick={() => this.editUser(Object.assign({}, props.original))}
										          >
											          <i className={"fa fa-pencil-square"}/>
										          </NTMButton>
									          </div>
								          }] :
							          [
								          {
									          Header: 'Nome',
									          accessor: 'firstName' // String-based value accessors!
								          },
								          {
									          Header: 'Cognome',
									          accessor: 'lastName',
								          },
								          {
									          Header: 'Email',
									          accessor: 'email'
								          },
								          {
									          Header: 'Città',
									          accessor: 'city'
								          },
								          {
									          Header: 'Data di nascita',
									          accessor: 'birthday'
								          },
								          {
									          Header: 'Data di iscrizione',
									          accessor: 'createdAt',
									          Cell: props => <div>
										          {moment(props.original.createdAt).format("DD/MM/YYYY")}
									          </div>,
									          filterable: false
								          },
								          {
									          Header: 'Ultimo accesso',
									          accessor: 'lastAccess',
									          Cell: props => <div>
										          {props.original.lastAccess && props.original.lastAccess !== -1 ?
											          moment(props.original.lastAccess).format("DD/MM/YYYY")
											          :
											          "-"
										          }
									          </div>,
									          sortMethod: (a = 0, b = 0) => {
										          return b - a;
									          },
									          filterable: false
								          },
								          {
									          Header: "Tipologia",
									          accessor: 'type',
									          Cell: props => <div>
										          {this.findTypeName(props.original.type)}
									          </div>,
									          filterable: false
								          },
								          {
									          Header: "Abilitato",
									          accessor: 'enabled',
									          filterable: false,
									          Cell: props => <div>

										          {props.original.type === 4 ?
											          <div/>
											          :

											          <i className={"fa fa-circle"}
											             style={{
												             cursor: "pointer",
												             color: props.original.enabled ? "green" : "red"
											             }}
											             onClick={() =>
												             this.getConfirmMessage(
													             !props.original.enabled ? "Abilitare utente?" : "Disabilitare utente?",
													             props.original.enabled ?
														             "Se disabiliti l'utente non potrà più accedere all'applicazione" :
														             "Se riattivi l'utente avrà accesso completo all'app",
													             props.original.enabled ? "Disabilita" : "Abilita",
													             props.original.enabled ? "Utente Disabilitato" : "Utente abilitato",
													             () => this.props.setUserEnabled(props.original.uid, !props.original.enabled))
											             }
											          />
										          }
									          </div>
								          },
								          {
									          accessor: 'details',
									          filterable: false,
									          Cell: props => <div>
										          <NTMButton primary outline
										                     onClick={() => this.editUser(Object.assign({}, props.original))}
										          >
											          <i className={"fa fa-pencil-square"}/>
										          </NTMButton>
									          </div>
								          }]
					          }
					          body={data}
					          filterable
					          defaultPageSize={10}
					>
					</NTMTable>
				</CardBody>
			</Card>
		);
	}
}
