import {LOGIN, LOGOUT, GET_USERS} from "./userActions";
import {
	CLEAR,
	ERROR,
	GET_INVOICES,
	GET_OPEN_INVOICE,
	SUCCESS,
	LOADING,
	UPDATE_INVOICE,
	GET_TRANSACTIONS
} from "./usersActionsCreator";

const defaultState = {
	ready: false,
	isLogged: false,
	loading: false,
	list: [],
	invoices: {closed: []}
};

export default function (state = defaultState, action) {
	switch (action.type) {
		case LOGIN:
			return {
				...state,
				ready: true,
				isLogged: true,
				data: action.payload
			};

		case LOGOUT:
			return {
				...state,
				ready: true,
				isLogged: false
			};
		case LOADING: {
			return {
				...state,
				loading: action.data
			};
		}
		case GET_USERS: {
			return {
				...state,
				list: action.data,
				loading: false
			};
		}
		case GET_INVOICES : {
			let invoices = Object.assign({}, state.invoices);
			invoices.closed = action.data;
			return {
				...state,
				loading: false,
				invoices
			};
		}
		case UPDATE_INVOICE: {
			let invoices = Object.assign({}, state.invoices);
			let index = invoices.closed.findIndex(d => d.invoiceNumber === action.data.id);
			if (invoices.closed[index])
				invoices.closed[index][action.data.type] = action.data.url;

			return {
				...state,
				invoices,
				loading: false
			};
		}
		case GET_OPEN_INVOICE: {
			let invoices = Object.assign({}, state.invoices);
			invoices.open = action.data;
			return {
				...state,
				loading: false,
				invoices
			};
		}
		case GET_TRANSACTIONS: {
			return {
				...state,
				transactions: action.data
			};
		}
		case ERROR: {
			return {
				...state,
				loading: false,
				error: action.data
			};
		}
		case SUCCESS: {
			return {
				...state,
				loading: false,
				success: true
			};
		}
		case CLEAR: {
			return {
				...state,
				loading: false,
				success: false,
				error: null
			};
		}
		default:
			return state;
	}
}
