export const SUCCESS = "SUCCESS_SHOP";
export const ERROR = "ERROR_SHOP";
export const CLEAR = "CLEAR_SHOP";
export const LOADING = "SHOPS_LOADING";

export const ADD_SHOP = "ADD_SHOP";
export const EDIT_SHOP = "EDIT_SHOP";
export const GET_SHOP_CATEGORIES = "GET_SHOP_CATEGORIES";
export const REMOVE_SHOP = "REMOVE_SHOP";

export const GET_INVOICES = "GET_INVOICES";
export const UPDATE_INVOICE = "UPDATE_INVOICE";
export const REMOVE_INVOICE = "REMOVE_INVOICE";

export function loading(loading = true) {
	return {
		type: LOADING,
		data: loading
	};
}

export function error(error) {
	return {
		type: ERROR,
		data: error
	};
}


export function success() {
	return {
		type: SUCCESS,
	};
}

export function clear() {
	return {
		type: CLEAR
	};
}

export function addShop(shop) {
	return {
		type: ADD_SHOP,
		data: shop
	};
}

export function editShop(shop) {
	return {
		type: EDIT_SHOP,
		data: shop
	};
}

export function removeShop(shop) {
	return {
		type: REMOVE_SHOP,
		data: shop
	};
}

export function getCategories(categories) {
	return {
		type: GET_SHOP_CATEGORIES,
		data: categories
	};
}

export function saveInvoices(shop, invoices) {
	return {
		type: GET_INVOICES,
		data: {shop, list: invoices}
	};
}

export function removeInvoice(shop, invoice) {
	return {
		type: REMOVE_INVOICE,
		data: {shop, invoice}
	};
}

export function updateStorageLink(shop, id, type, url) {
	return {
		type: UPDATE_INVOICE,
		data: {shop, id, type, url}
	};
}