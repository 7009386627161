import {firebaseDatabase, firebaseStorage} from '../../firebase';
import {
	addShop,
	editShop,
	removeShop,
	getCategories,
	error,
	loading,
	saveInvoices,
	updateStorageLink,
	removeInvoice,
	success
} from "./shopsActionsCreator";
import {Client, INVOICES, SHOPS} from "../utils/Client";
import moment from "moment";
import {CLEAR} from "../cards/cardsActionsCreator";

let client = new Client();

export function list() {
	firebaseDatabase.ref("shops").off();
	return dispatch => {
		let ref = firebaseDatabase.ref("shops");
		ref.on('child_added', (snap) => {
			dispatch(addShop(snap.val()));
		});
		ref.on('child_removed', (snap) => {
			dispatch(removeShop(snap.val()));
		});
		ref.on('child_changed', (snap) => {
			dispatch(editShop(snap.val()));
		});
	};
}

export function getShopCategories() {
	return dispatch => {
		firebaseDatabase.ref("shop_category").on("value", data => dispatch(getCategories(data.val())), err => console.log(err));
	};
}

export function save(shop) {
	return dispatch => {
		dispatch(loading());
		let promise;
		if (shop.id) promise = client.update(shop, SHOPS);
		else {
			shop.id = new Date().getTime();
			promise = client.save(shop, SHOPS);
		}
		promise
			.then(() => {
				dispatch(success());
			})
			.catch(e => manageError(e, dispatch));
	};
}

export function deleteShop(shop) {
	return () => client.delete(shop, SHOPS);
}

export function getInvoices(shop) {
	return dispatch => {
		dispatch(loading());
		client.get(INVOICES, shop)
			.then(response => {
				dispatch(saveInvoices(shop, response.data));
				response.data.map(i => {
					i.invoiceDate = moment(i.invoiceDate).format("DD/MM/YYYY");
					if (i.link) firebaseStorage.refFromURL(i.link).getDownloadURL()
						.then(url => dispatch(updateStorageLink(shop, i.invoiceNumber, "link", url)))
						.catch(e => dispatch(removeInvoice(shop, i.invoiceNumber)));
					if (i.eInvoiceLink) firebaseStorage.refFromURL(i.eInvoiceLink).getDownloadURL()
						.then(url => dispatch(updateStorageLink(shop, i.invoiceNumber, "eInvoiceLink", url)))
						.catch(e => dispatch(removeInvoice(shop, i.invoiceNumber)));
				});
			})
			.catch(e => manageError(e, dispatch));
	};
}

export function clear() {
	return {
		type: CLEAR
	};
}

function manageError(e, dispatch) {
	dispatch(loading(false));
	if (e.response) {
		// The request was made and the server responded with a status code
		// that falls out of the range of 2xx
		dispatch(error(e.response.data.message));
	} else {
		// Something happened in setting up the request that triggered an Error
		console.log('Error', e.message);
		dispatch(error(e.message));
	}
}
