import React, {Component} from "react";
import {Col, Row} from "reactstrap";
import NTMTable from "../../components/Table/NTMTable";
import NTMButton from "../../components/Buttons/NTMButton";
import NTMFormModal from "../../components/Modal/NTMFormModal";
import NTMLoadingModal from "../../components/Modal/NTMLoadingModal";
import {ErrorDialog, SuccessDialog, ConfirmDialog} from "@nextome/messages";

export default class ProductPage extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentWillReceiveProps(props) {
		if (props.loading) {
			this.openModal(<NTMLoadingModal/>);
		} else if (props.error) {
			this.openModal(<ErrorDialog
				message={props.error}
				onClose={() => {
					this.props.clear();
					this.closeModal();
				}}
			/>);
		} else if (props.success) {
			this.openModal(
				<SuccessDialog
					title={"Operazione eseguita"}
					onClose={() => {
						this.props.clear();
						this.closeModal();
					}}
				/>
			);
		}
	}

	closeModal = () => {
		this.setState({
			modal: null
		});
	};

	openModal = (modal) => {
		this.setState({
			modal
		});
	};

	editCategory = (category) => {
		this.openModal(
			<NTMFormModal
				initialValues={category}
				formData={[
					{
						type: "text",
						name: "name",
						label: "Nome"
					},
					{
						type: "checkbox",
						name: "erogable",
						label: "Prodotto erogabile"
					},
					{
						type: "number",
						name: "dailyErogation",
						label: "Quantità massima giornaliera (0 = senza limitazioni)",
						props: {
							min: 0,
							step: 1
						},
						dependsOn: "erogable"
					},
					{
						type: "number",
						name: "erogationInterval",
						label: "Intervallo di erogazione (in giorni)",
						props: {
							min: 0,
							step: 1
						},
						dependsOn: "erogable"
					},
				]}
				onSubmit={(values) => {
					console.log(values);
					this.props.saveCategory(values);
				}}
				onClose={this.closeModal}
				title={category ? `Modifica ${category.name}` : "Crea"}
			/>
		);
	};

	editProduct = (product) => {
		this.openModal(
			<NTMFormModal
				initialValues={product}
				formData={[
					{
						type: "text",
						name: "name",
						label: "Nome"
					},
					{
						type: "number",
						name: "tucum",
						label: "Valore in Tucum",
						props: {
							min: 0,
							step: 0.01
						}
					},
					{
						type: "number",
						name: "iva",
						label: "IVA",
						props: {
							min: 0,
							step: 1
						}
					},
					{
						name: "product_category",
						label: "Categoria",
						options: [{name: "Seleziona...", value: -1}].concat(this.props.categories.map(c => {
							return {name: c.name, value: c.id};
						}))
					}
				]}
				onSubmit={(values) => {
					console.log(values);
					if (!values.product_category)
						this.openModal(<ErrorDialog
							message={"Inserisci categoria!"}
							onClose={() => {
								this.editProduct(values);
							}}
						/>);
					else this.props.saveGenericProduct(values);
				}}
				onClose={this.closeModal}
				title={product ? `Modifica ${product.name}` : "Crea"}
			/>
		);
	};

	deleteCategory = (category) => {
		this.openModal(
			<ConfirmDialog
				message={`Vuoi eliminare la categoria ${category.name}?`}
				onCancel={this.closeModal}
				onConfirm={() => this.props.deleteCategory(category)}
			/>
		);
	};

	deleteProduct = (product) => {
		this.openModal(
			<ConfirmDialog
				message={`Vuoi eliminare il prodotto generico ${product.name}?`}
				onCancel={this.closeModal}
				onConfirm={() => this.props.deleteGenericProduct(product)}
			/>
		);
	};

	render() {
		return (
			<div className="animated fadeIn">
				{this.state.modal}
				<Row>
					<NTMButton primary onClick={() => this.editCategory()}>{"Crea nuova Categoria"}</NTMButton>
					<NTMButton primary onClick={() => this.editProduct()}>{"Crea nuovo Prodotto generico"}</NTMButton>
				</Row>
				<Row>
					<Col>
						<NTMTable
							title={"Categorie di prodotto"}
							header={
								[{
									Header: 'Nome',
									accessor: 'name' // String-based value accessors!
								}, {
									Header: 'Quantità massima giornaliera',
									accessor: 'dailyErogation',
									Cell: props => <div>
										{props.original.erogable ? props.original.dailyErogation : "Non Erog."}
									</div>

								}, {
									Header: 'Intervallo di erogazione (in giorni)',
									accessor: 'erogationInterval',
									Cell: props => <div>
										{props.original.erogable ? props.original.erogationInterval : "Non Erog."}
									</div>
								}, {
									accessor: 'details',
									Cell: props => <div>
										<NTMButton primary outline
										           onClick={() => this.editCategory(props.original)}
										>
											<i className={"fa fa-pencil-square"}/>
										</NTMButton>
										<NTMButton red
										           onClick={() => this.deleteCategory(props.original)}
										>
											<i className={"fa fa-trash"}/>
										</NTMButton>
									</div>
								}]}
							body={this.props.categories}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<NTMTable
							title={"Prodotti generici"}
							header={
								[{
									Header: 'Nome',
									accessor: 'name' // String-based value accessors!
								}, {
									Header: "Categoria",
									accessor: 'product_category',
									Cell: props => <div>
										{this.props.categories.find(c => c.id === props.original.product_category) ?
											this.props.categories.find(c => c.id === props.original.product_category).name :
											props.original.product_category}
									</div>
								}, {
									Header: 'Valore in Tucum',
									accessor: 'tucum' // String-based value accessors!
								}, {
									Header: 'IVA (%)',
									accessor: 'iva' // String-based value accessors!
								}, {
									Header: "Dettagli",
									accessor: 'details',
									Cell: props => <div>
										<NTMButton primary outline
										           onClick={() => this.editProduct(props.original)}
										>
											<i className={"fa fa-pencil-square"}/>
										</NTMButton>
										<NTMButton red
										           onClick={() => this.deleteProduct(props.original)}
										>
											<i className={"fa fa-trash"}/>
										</NTMButton>
									</div>
								}]}
							body={this.props.generics}
						/>
					</Col>
				</Row>
			</div>
		);
	}
};
